import {
  child,
  Database,
  DatabaseReference,
  endAt,
  equalTo,
  get,
  getDatabase,
  limitToLast,
  remove,
  orderByChild,
  orderByKey,
  push,
  query,
  ref,
  set,
  startAt,
  update,
} from 'firebase/database'
import { getApp } from 'firebase/app'
import { FirebaseInitializer, FirebaseInitializerStatusesEnum } from './firebase-initializer'

export class RtdbClient {

  #database: Database

  constructor(firebaseInitializer: FirebaseInitializer) {
    firebaseInitializer.initializationStatus$.subscribe(state => {
      if (state === FirebaseInitializerStatusesEnum.INITIALIZED) {
        this.#database = getDatabase(getApp())
      }
    })
  }

  getRef(path: string): DatabaseReference {
    return child(ref(this.#database), path)
  }

  async pushChild(path: string, attributes: Record<string, unknown>) {
    const ref = push(this.getRef(path))
    await set(ref, this.getAttributes(attributes))
    return ref.key
  }

  insertChild(path: string, attributes: Record<string, unknown>) {
    return set(this.getRef(path), this.getAttributes(attributes))
  }

  updateChild(path: string, attributes: Record<string, unknown>) {
    return update(this.getRef(path), this.getAttributes(attributes))
  }

  deleteChild(path: string) {
    return remove(this.getRef(path))
  }

  newChildId(path: string) {
    const ref = push(this.getRef(path))
    return ref.key
  }

  /**
   * Read data once with get() (to get a snapshot of the data from the database).
   * https://firebase.google.com/docs/database/web/read-and-write#read_data_once
   * For observers (on and once) check https://firebase.google.com/docs/database/web/read-and-write#read_data
   * and extend this client.
   */
  async getNodes(path: string) {
    const results = await get(this.getRef(path))
    if (results) {
      return results.val()
    }
    return undefined
  }

  async getNodesOrderedByChild(path: string, child: string) {
    const results = await get(query(this.getRef(path), orderByChild(child)))
    if (results) {
      return results.val()
    }
    return undefined
  }

  async getNodesOrderedByChildMatchingValue(
    path: string,
    child: string,
    value: string | number | boolean
  ) {
    const results = await get(
      query(this.getRef(path), orderByChild(child), equalTo(value))
    )
    if (results) {
      return results.val()
    }
    return undefined
  }

  async getNodesOrderedByChildUntil(
    path: string,
    child: string,
    endDate: string
  ) {
    const results = await get(
      query(this.getRef(path), orderByChild(child), endAt(endDate))
    )
    if (results) {
      return results.val()
    }
    return undefined
  }

  async getNodesOrderedByChildStartingAt(
    path: string,
    child: string,
    startDate: string
  ) {
    const results = await get(
      query(this.getRef(path), orderByChild(child), startAt(startDate))
    )
    if (results) {
      return results.val()
    }
    return undefined
  }

  async getNodesOrderedByKeyLimitToLast(path: string, nodes: number) {
    const results = await get(
      query(this.getRef(path), orderByKey(), limitToLast(nodes))
    )
    if (results) {
      return results.val()
    }
    return undefined
  }

  async getNodesOrderedByKeyWithinInterval(
    path: string,
    startDate: string,
    endDate: string
  ) {
    const results = await get(
      query(this.getRef(path), orderByKey(), startAt(startDate), endAt(endDate))
    )
    if (results) {
      return results.val()
    }
    return undefined
  }

  // TODO move to common library (actually, should we skip empty strings??)
  private getAttributes(attributes: Record<string, unknown>) {
    const data: Record<string, unknown> = {}

    for (const a in attributes) {
      if (attributes[a] !== null && attributes[a] !== '') {
        data[a] = attributes[a]
      }
    }
    return data
  }
}
