import {
  LanguageCodesEnum,
  ProductsEnum,
  GroupsEnum,
  DeviceInfo,
} from '../..'

import {
  AccountCreatedEvent,
  PasswordRecoveryAttemptedEvent,
  PasswordResetEvent,
  AgentsEnum,
  EventsEnum,
  EventLogProductsEnum,
  SidesEnum,
  ResultsEnum,
  AccountDeletedEvent,
  DeletionReasonsEnum,
  AuthenticationProviderEnum,
} from '..'

export const AccountEventLogFactory = {
  createAccountCreatedEvent: (data: {
    timestamp: Date,
    product: EventLogProductsEnum,
    uid: string,
    email: string,
    language: LanguageCodesEnum,
    signupSource: ProductsEnum,
    authenticationProvider: AuthenticationProviderEnum,
  }): AccountCreatedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.ACCOUNT_CREATED,
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    timestamp: data.timestamp,
    uid: data.uid,
    email: data.email.toLowerCase(),
    language: data.language,
    signupSource: data.signupSource,
    authenticationProvider: data.authenticationProvider,
  }),

  createPasswordRecoveryAttemptedEvent: (data: {
    email: string,
    product: EventLogProductsEnum,
    device: DeviceInfo,
    errorReason?: string
  }): PasswordRecoveryAttemptedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.PASSWORD_RECOVERY_ATTEMPTED,
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    email: data.email.toLowerCase(),
    result: data.errorReason ? ResultsEnum.ERROR : ResultsEnum.OK,
    errorReason: data.errorReason || null,
    deviceId: data.device.deviceUuid || null,
    os: data.device.os || null,
    osVersion: data.device.osVersion || null,
    platform: data.device.platform || null,
    appVersion: data.device.appVersion || null,
    deviceType: data.device.type || null
  }),

  createPasswordResetEvent: (data: {
    email: string,
    product: EventLogProductsEnum,
    device: DeviceInfo,
  }): PasswordResetEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.PASSWORD_RESET,
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    email: data.email.toLowerCase(),
    result: ResultsEnum.OK,
    deviceId: data.device.deviceUuid || null,
    os: data.device.os || null,
    osVersion: data.device.osVersion || null,
    platform: data.device.platform || null,
    appVersion: data.device.appVersion || null,
    deviceType: data.device.type || null
  }),

  createAccountDeletedEvent: (data: {
    uid: string,
    email: string,
    product: EventLogProductsEnum,
    deletionReason: DeletionReasonsEnum,
    timestamp: Date
  }): AccountDeletedEvent => ({
    agent: data.deletionReason === DeletionReasonsEnum.EMAIL_UNVERIFIED ? AgentsEnum.SYSTEM : AgentsEnum.USER,
    event: EventsEnum.ACCOUNT_DELETED,
    group: GroupsEnum.COGNI,
    product: data.product,
    side: data.deletionReason === DeletionReasonsEnum.EMAIL_UNVERIFIED ? SidesEnum.SERVER : SidesEnum.CLIENT_UX,
    timestamp: data.timestamp,
    uid: data.uid,
    email: data.email,
    deletionReason: data.deletionReason
  })
}
