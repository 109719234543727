import { GroupsEnum } from '../..'
import { InterconnectionEvent, PatientArchivedEvent, RecordSharingEvent } from './event-logs.types'
import { AgentsEnum, EventLogProductsEnum, EventsEnum, SidesEnum } from './event-logs.enums'

export const RecordSharingEventLogFactory = {
  createInterconnectionCreatedEvent: (data: {
    patientId: string,
    therapistId: string,
    interconnectionId: string,
  }): InterconnectionEvent => ({
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.DASHBOARD,
    side: SidesEnum.SERVER,
    agent: AgentsEnum.SYSTEM,
    event: EventsEnum.INTERCONNECTION_CREATED,
    timestamp: new Date(),
    patientId: data.patientId,
    therapistId: data.therapistId,
    interconnectionId: data.interconnectionId,
  }),

  createInterconnectionTerminatedEvent: (data: {
    patientId: string,
    therapistId: string,
    interconnectionId: string,
  }): InterconnectionEvent => ({
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.DASHBOARD,
    side: SidesEnum.SERVER,
    agent: AgentsEnum.SYSTEM,
    event: EventsEnum.INTERCONNECTION_TERMINATED,
    timestamp: new Date(),
    patientId: data.patientId,
    therapistId: data.therapistId,
    interconnectionId: data.interconnectionId,
  }),

  createRecordSharingStoppedEvent: (data: {
    patientId: string,
    therapistId: string,
    interconnectionId: string,
  }): RecordSharingEvent => ({
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.APP,
    side: SidesEnum.CLIENT_UX,
    agent: AgentsEnum.USER,
    event: EventsEnum.RECORD_SHARING_STOPPED,
    timestamp: new Date(),
    patientId: data.patientId,
    therapistId: data.therapistId,
    interconnectionId: data.interconnectionId,
  }),

  createPatientArchivedEvent: (data: {
    patientId: string,
    therapistId: string,
    interconnectionId: string,
  }): PatientArchivedEvent => ({
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.DASHBOARD,
    side: SidesEnum.CLIENT_UX,
    agent: AgentsEnum.USER,
    event: EventsEnum.PATIENT_ARCHIVED,
    timestamp: new Date(),
    patientId: data.patientId,
    therapistId: data.therapistId,
    interconnectionId: data.interconnectionId,
  }),
}