import { CustomAction } from '..'

const generateCustomActionLink = (link: CustomAction): string => {
  let actionLink = `${link.protocol}://${link.domain}/customAction?mode=${link.mode}&oobCode=${link.oobCode}&apiKey=${link.apiKey}&lang=${link.languageCode}`
  if (link.continueUrl) {
    actionLink = actionLink.concat('&continueUrl=', link.continueUrl)
  }
  return actionLink
}

export { generateCustomActionLink }
