import { GroupsEnum, InvitationSourcesEnum } from '../..'
import { InvitationDBDocument } from '../db-types'
import { InvitationEvent } from './event-logs.types'
import { AgentsEnum, EventLogProductsEnum, EventsEnum, SidesEnum } from './event-logs.enums'

export const InvitationEventLogFactory = {
  createInvitationSentEvent: (data: {
    invitation: InvitationDBDocument,
    timestamp: Date
  }): InvitationEvent => ({
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.APP,
    side: SidesEnum.CLIENT_UX,
    agent: AgentsEnum.USER,
    event: EventsEnum.INVITATION_SENT,
    timestamp: data.timestamp,
    patientId: data.invitation.patientId,
    therapistId: data.invitation.therapistId,
    invitationId: data.invitation.invitationId,
    invitationSource: data.invitation.invitationSource || InvitationSourcesEnum.PATIENT
  }),

  createInvitationAcceptedEvent: (data: {
    invitation: InvitationDBDocument,
    timestamp: Date
  }): InvitationEvent => ({
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.DASHBOARD,
    side: SidesEnum.CLIENT_UX,
    agent: AgentsEnum.USER,
    event: EventsEnum.INVITATION_ACCEPTED,
    timestamp: data.timestamp,
    patientId: data.invitation.patientId,
    therapistId: data.invitation.therapistId,
    invitationId: data.invitation.invitationId,
    invitationSource: data.invitation.invitationSource || InvitationSourcesEnum.PATIENT
  }),

  createInvitationRefusedEvent: (data: {
    invitation: InvitationDBDocument,
    timestamp: Date
  }): InvitationEvent => ({
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.DASHBOARD,
    side: SidesEnum.CLIENT_UX,
    agent: AgentsEnum.USER,
    event: EventsEnum.INVITATION_REFUSED,
    timestamp: data.timestamp,
    patientId: data.invitation.patientId,
    therapistId: data.invitation.therapistId,
    invitationId: data.invitation.invitationId,
    invitationSource: data.invitation.invitationSource || InvitationSourcesEnum.PATIENT
  }),

  createInvitationCancelledEvent: (data: {
    invitation: InvitationDBDocument,
    timestamp: Date
  }): InvitationEvent => ({
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.APP,
    side: SidesEnum.CLIENT_UX,
    agent: AgentsEnum.USER,
    event: EventsEnum.INVITATION_CANCELLED,
    timestamp: data.timestamp,
    patientId: data.invitation.patientId,
    therapistId: data.invitation.therapistId,
    invitationId: data.invitation.invitationId,
    invitationSource: data.invitation.invitationSource || InvitationSourcesEnum.PATIENT
  }),
}
