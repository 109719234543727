import {
  GenderCodesEnum,
  LanguageCodesEnum,
  GroupsEnum
} from '../..'

import {
  AgentsEnum,
  EventsEnum,
  EventLogProductsEnum,
  ProfessionalId,
  ProfessionalInformation,
  SidesEnum,
  UserCreatedEvent,
  UserUpdatedEvent,
  AppProfileCreatedEvent,
  DashboardProfileCreatedEvent
} from '..'

export const UserProfileEventLogFactory = {
  createUserCreatedEvent: (data: {
    product: EventLogProductsEnum,
    uid: string,
    gender: GenderCodesEnum,
    birthday?: string,
    cognicode?: string,
    professionalInformation?: ProfessionalInformation,
    professionalId?: ProfessionalId
  }): UserCreatedEvent => {
    const event: UserCreatedEvent = {
      agent: AgentsEnum.USER,
      event: EventsEnum.USER_CREATED,
      group: GroupsEnum.COGNI,
      product: data.product,
      side: SidesEnum.CLIENT_UX,
      timestamp: new Date(),
      uid: data.uid,
      gender: data.gender,
    }
    if (data.birthday) {
      event.birthday = data.birthday
    }
    if (data.cognicode) {
      event.cognicode = data.cognicode
    }
    if (data.professionalInformation) {
      event.profession = data.professionalInformation.profession
      event.graduationYear = data.professionalInformation.graduationYear
      event.educationLevel = data.professionalInformation.educationLevel
      if (data.professionalInformation.otherProfession) {
        event.otherProfession = data.professionalInformation.otherProfession
      }
    }
    if (data.professionalId) {
      event.proIdNumber = data.professionalId.number
      event.proIdState = data.professionalId.state
      event.proIdType = data.professionalId.type
    }
    return event
  },

  createUserUpdatedEvent: (data: {
    product: EventLogProductsEnum,
    uid: string,
    birthday?: string,
    birthdayUpdated?: boolean,
    gender?: string,
    language?: LanguageCodesEnum,
    professionalId?: ProfessionalId,
    professionalInformation?: ProfessionalInformation,
    cognicode?: string
  }): UserUpdatedEvent => {
    const event: UserUpdatedEvent = {
      agent: AgentsEnum.USER,
      event: EventsEnum.USER_UPDATED,
      group: GroupsEnum.COGNI,
      product: data.product,
      side: SidesEnum.CLIENT_UX,
      timestamp: new Date(),
      uid: data.uid
    }
    if (data.birthday) {
      event.birthday = data.birthday
    }
    if (data.birthdayUpdated) {
      event.birthdayUpdated = data.birthdayUpdated
    }
    if (data.gender) {
      event.gender = data.gender
    }
    if (data.language) {
      event.language = data.language
    }
    if (data.cognicode) {
      event.cognicode = data.cognicode
    }
    if (data.professionalId) {
      event.proIdNumber = data.professionalId.number
      event.proIdState = data.professionalId.state
      event.proIdType = data.professionalId.type
    }
    if (data.professionalInformation) {
      event.profession = data.professionalInformation.profession
      event.graduationYear = data.professionalInformation.graduationYear
      event.educationLevel = data.professionalInformation.educationLevel
      if (data.professionalInformation.otherProfession) {
        event.otherProfession = data.professionalInformation.otherProfession
      }
    }
    return event
  },

  createAppProfileCreatedEvent: (data: {
    uid: string,
  }): AppProfileCreatedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.USER_UPDATED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.APP,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid
  }),

  createDashboardProfileCreatedEvent: (data: {
    uid: string,
    cognicode: string
  }): DashboardProfileCreatedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.USER_UPDATED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.APP,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    cognicode: data.cognicode
  }),
}
