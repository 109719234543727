import {
  ContentAccessedEvent,
  GroupsEnum,
  LanguageCodesEnum,
  MinicardClickedEvent,
  MinicardIdsEnum,
  MuralTileClikedEvent,
  PageAccessedEvent,
  convertFromMinicardIdToMinicardName,
  DeviceInfo
} from '../..'
import { AgentsEnum, EventLogProductsEnum, EventPageIdsEnum, EventsEnum, SidesEnum } from './event-logs.enums'

export const ContentEventLogFactory = {
  createMinicardClickedEvent: (data: {
    uid: string,
    minicardId: MinicardIdsEnum,
    device: DeviceInfo,
  }): MinicardClickedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.MINICARD_CLICKED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.APP,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    id: convertFromMinicardIdToMinicardName(data.minicardId),
    deviceId: data.device.deviceUuid || null,
    os: data.device.os || null,
    osVersion: data.device.osVersion || null,
    platform: data.device.platform || null,
    appVersion: data.device.appVersion || null,
    deviceType: data.device.type || null
  }),

  createMuralTileClickedEvent: (data: {
    uid: string,
    tileId: string,
    device: DeviceInfo,
  }): MuralTileClikedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.MURAL_TILE_CLICKED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.DASHBOARD,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    tileId: data.tileId,
    deviceId: data.device.deviceUuid || null,
    os: data.device.os || null,
    osVersion: data.device.osVersion || null,
    platform: data.device.platform || null,
    appVersion: data.device.appVersion || null,
    deviceType: data.device.type || null
  }),

  createContentAccessedEvent: (data : {
    product: EventLogProductsEnum
    uid: string
    contentId: string
    language: LanguageCodesEnum
    deviceLanguage: string,
    device: DeviceInfo,
  }): ContentAccessedEvent => ({
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    agent: AgentsEnum.USER,
    event: EventsEnum.CONTENT_ACCESSED_EVENT,
    timestamp: new Date(),
    uid: data.uid,
    language: data.language,
    deviceLanguage: data.deviceLanguage,
    contentId: data.contentId,
    deviceId: data.device.deviceUuid || null,
    os: data.device.os || null,
    osVersion: data.device.osVersion || null,
    platform: data.device.platform || null,
    appVersion: data.device.appVersion || null,
    deviceType: data.device.type || null
  }),

  createPageAccessedEvent: (data : {
    product: EventLogProductsEnum
    uid: string
    pageId: EventPageIdsEnum
    language: LanguageCodesEnum
    deviceLanguage: string,
    device: DeviceInfo,
  }): PageAccessedEvent => ({
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    agent: AgentsEnum.USER,
    event: EventsEnum.PAGE_ACCESSED_EVENT,
    timestamp: new Date(),
    uid: data.uid,
    language: data.language,
    deviceLanguage: data.deviceLanguage,
    pageId: data.pageId,
    deviceId: data.device.deviceUuid || null,
    os: data.device.os || null,
    osVersion: data.device.osVersion || null,
    platform: data.device.platform || null,
    appVersion: data.device.appVersion || null,
    deviceType: data.device.type || null
  }),
}
