import { ErrorHandler, Injectable } from "@angular/core";

const firstSource = (stack: string): string => {
  const sources = /[^\/]+.(t|j)s:\d+:\d+/.exec(String(stack));
  return sources ? sources[0] : '';
};

const filterStack = (stack: string, lines: number): string => (
  String(stack)
  .split('\n')
  .filter(line => !line.endsWith('[<root>]') && line.indexOf('polyfill') == -1) // these don't help
  .map(line => line.replace(location.origin + '/', '').split(/:\d+:\d+/)[0])
  .slice(0, lines)
  .join('|')
);

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    if (document.getElementById("error-info")) {
      // an error is already being shown
      return;
    }
    // TODO: make the path reflect what the user is doing, right now
    // it stays mostly the same (/app/features/main) so it doesn't help
    // let where = location.pathname;
    const message = filterStack(error.message, 3); // propagated errors include stack here
    const stack = filterStack(error.stack, 5);
    let cognicode = 'XXXXXX';
    try {
      cognicode = document.querySelector('ion-label.lbl-user').innerHTML;
      cognicode = cognicode.slice(cognicode.length - 6);
    } finally {}
    const src = firstSource(error.message) || firstSource(error.stack);
    let shownMessage = [message, cognicode, stack].join(' ');
    if (src) {
      // the first line:column pair might be useful, so put it back
      shownMessage = shownMessage.replace(src.split(':')[0], src);
      // use `cut -cCOL1-COL2 source.js` for single-line (minified) sources
    }
    (window as any).cogniErrorDialog(shownMessage);
  }
}
