import {
  addDoc,
  collection,
  collectionGroup,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  Firestore,
  getDoc,
  getDocFromCache,
  getDocs,
  getDocsFromCache,
  getFirestore,
  Query,
  setDoc,
  updateDoc,
  Timestamp,
} from 'firebase/firestore'
import { getApp } from 'firebase/app';
import { FirebaseInitializer, FirebaseInitializerStatusesEnum } from './firebase-initializer';

export class FirestoreClient {

  #database: Firestore

  constructor(firebaseInitializer: FirebaseInitializer) {
    firebaseInitializer.initializationStatus$.subscribe(state => {
      if (state === FirebaseInitializerStatusesEnum.INITIALIZED) {
        this.#database = getFirestore(getApp())
      }
    })
  }
  
  getFirestore() {
    return this.#database;
  }

  collRef(path: string) {
    return collection(this.#database, path)
  }

  docRef(
    collectionReference: CollectionReference,
    documentKey: string
  ) {
    return doc(collectionReference, documentKey)
  }

  newDocId(
    collectionReference: CollectionReference
  ) {
    return doc(collectionReference).id
  }

  async getColl(
    collectionReference: CollectionReference | Query<DocumentData>,
    fromCache?: boolean
  ) {
    if (fromCache) {
      const collection = await getDocsFromCache(collectionReference)
      return collection.docs
    }
    const collection = await getDocs(collectionReference)
    return collection.docs
  }

  async getDoc(
    documentReferent: DocumentReference,
    fromCache?: boolean
  ) {
    if (fromCache) {
      const document = await getDocFromCache(documentReferent)
      return document.data()
    }
    const document = await getDoc(documentReferent)
    return document.data()
  }

  collGroupRef(collectionId: string) {
    return collectionGroup(this.#database, collectionId)
  }

  /**
   * 'Firestore add' won't resolve while offline, so if offline mode is supported do not AWAIT for this call
   */
  async pushDoc(
    collectionReference: CollectionReference,
    attributes: DocumentData
  ) {
    const document = await addDoc(collectionReference, attributes)
    return document.id
  }

  /**
   * 'Firestore set' won't resolve while offline, so if offline mode is supported do not AWAIT for this call
   */
  insertDoc(
    collectionReference: CollectionReference,
    documentKey: string,
    attributes: DocumentData,
    merge = false
  ) {
    return setDoc(this.docRef(collectionReference, documentKey), attributes, {
      merge: merge
    })
  }

  /**
   * 'Firestore update' won't resolve while offline, so if offline mode is supported do not AWAIT for this call
   */
  updateDoc(
    collectionReference: CollectionReference,
    documentKey: string,
    attributes: DocumentData
  ) {
    return updateDoc(this.docRef(collectionReference, documentKey), attributes)
  }

  /**
   * 'Firestore delete' won't resolve while offline, so if offline mode is supported do not AWAIT for this call
   */
  deleteDoc(
    collectionReference: CollectionReference,
    documentKey: string
  ) {
    return deleteDoc(this.docRef(collectionReference, documentKey))
  }

  fromTimestampToDateByDocument(
    document: DocumentData,
    pattern: string
  ) {
    if (document) {
      for (const field in document) {
        if (field.indexOf(pattern) >= 0 && document[field]) {
          document[field] = (document[field] as Timestamp).toDate()
        }
      }
    }
  }
  
  fromTimestampToDateByKey(
    document: DocumentData,
    key: string
  ) {
    if (document && document[key]) {
      return (document[key] as Timestamp).toDate()
    }
    return undefined
  }
}
