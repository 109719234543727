import { Buffer } from 'buffer'

import {
  AbridgedGenderCodesEnum,
  GenderCodesEnum,
  ProfessionCodeNumbersEnum,
  ProfessionCodesEnum,
  MinicardIdsEnum
} from '..'

export const convertFromGenderToAbridgedGender = (
  gender: GenderCodesEnum
): AbridgedGenderCodesEnum => {
  switch (gender) {
    case GenderCodesEnum.FEMALE:
      return AbridgedGenderCodesEnum.FEMALE
    case GenderCodesEnum.MALE:
      return AbridgedGenderCodesEnum.MALE
    case GenderCodesEnum.UNKNOWN:
      return AbridgedGenderCodesEnum.UNKNOWN
  }
}

export const convertFromAbridgedGenderToGender = (
  gender: AbridgedGenderCodesEnum
): GenderCodesEnum => {
  switch (gender) {
    case AbridgedGenderCodesEnum.FEMALE:
      return GenderCodesEnum.FEMALE
    case AbridgedGenderCodesEnum.MALE:
      return GenderCodesEnum.MALE
    case AbridgedGenderCodesEnum.UNKNOWN:
      return GenderCodesEnum.UNKNOWN
  }
}

export const fromBinarytoBase64 = (input: string) =>
  Buffer.from(input, 'binary').toString('base64')

export const fromBase64toBinary = (input: string) =>
  Buffer.from(input, 'base64').toString('binary')


export const convertFromProfessionStringFormatToNumberFormat = (
  profession: ProfessionCodesEnum
): ProfessionCodeNumbersEnum => {
  switch (profession) {
    case ProfessionCodesEnum.PSYCHIATRY:
      return ProfessionCodeNumbersEnum.PSYCHIATRY
    case ProfessionCodesEnum.PSYCHOLOGY:
      return ProfessionCodeNumbersEnum.PSYCHOLOGY
    case ProfessionCodesEnum.NEUROLOGY:
      return ProfessionCodeNumbersEnum.NEUROLOGY
    case ProfessionCodesEnum.NEUROPSYCHIATRY:
      return ProfessionCodeNumbersEnum.NEUROPSYCHIATRY
    case ProfessionCodesEnum.NEUROPSYCHOLOGY:
      return ProfessionCodeNumbersEnum.NEUROPSYCHOLOGY
    case ProfessionCodesEnum.COACHING:
      return ProfessionCodeNumbersEnum.COACHING
    case ProfessionCodesEnum.OTHER:
      return ProfessionCodeNumbersEnum.OTHER
    case ProfessionCodesEnum.UNKNOWN:
      return ProfessionCodeNumbersEnum.UNKNOWN
    default:
      return ProfessionCodeNumbersEnum.UNKNOWN
  }
}

export const convertFromProfessionNumberFormatToStringFormat = (
  profession: ProfessionCodeNumbersEnum
): ProfessionCodesEnum => {
  switch (profession) {
    case ProfessionCodeNumbersEnum.PSYCHIATRY:
      return ProfessionCodesEnum.PSYCHIATRY
    case ProfessionCodeNumbersEnum.PSYCHOLOGY:
      return ProfessionCodesEnum.PSYCHOLOGY
    case ProfessionCodeNumbersEnum.NEUROLOGY:
      return ProfessionCodesEnum.NEUROLOGY
    case ProfessionCodeNumbersEnum.NEUROPSYCHIATRY:
      return ProfessionCodesEnum.NEUROPSYCHIATRY
    case ProfessionCodeNumbersEnum.NEUROPSYCHOLOGY:
      return ProfessionCodesEnum.NEUROPSYCHOLOGY
    case ProfessionCodeNumbersEnum.COACHING:
      return ProfessionCodesEnum.COACHING
    case ProfessionCodeNumbersEnum.OTHER:
      return ProfessionCodesEnum.OTHER
    case ProfessionCodeNumbersEnum.UNKNOWN:
      return ProfessionCodesEnum.UNKNOWN
    default:
      return ProfessionCodesEnum.UNKNOWN
  }
}

export const convertFromMinicardIdToMinicardName = (minicardId: MinicardIdsEnum) => {
  switch(minicardId) {
    case MinicardIdsEnum.DISCOVER_COGNI: return 'DISCOVER-COGNI'
    case MinicardIdsEnum.DISCOVER_DASHBOARD: return 'DISCOVER-DASHBOARD'
    case MinicardIdsEnum.GOT_THERAPIST: return 'GOT-THERAPIST'
    case MinicardIdsEnum.JUST_TESTING: return 'JUST-TESTING'
    case MinicardIdsEnum.QUIZ: return 'QUIZ'
    case MinicardIdsEnum.REMINDER: return 'REMINDER'
    case MinicardIdsEnum.SHARE: return 'SHARE'
    case MinicardIdsEnum.MATCHMAKING: return 'MATCHMAKING'
  }
}
