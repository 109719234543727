import { child, Database, getDatabase, onValue, ref } from 'firebase/database'
import { BehaviorSubject } from 'rxjs'
import { FirebaseConnectionStatusesEnum } from '..'
import { getApp } from 'firebase/app'
import {
  FirebaseInitializer,
  FirebaseInitializerStatusesEnum,
} from './firebase-initializer'


export class FirebaseConnectionStatusClient {

  #database: Database

  // to avoid showing disconnected upon launch or when transitioning
  // the app from background to foreground after some idle time
  #databaseConnection = FirebaseConnectionStatusesEnum.CONNECTED
  #connected = true

  #disconnectionTimeout: NodeJS.Timeout

  #connectionStateSubject = new BehaviorSubject<FirebaseConnectionStatusesEnum>(
    FirebaseConnectionStatusesEnum.CONNECTED
  )
  public readonly connectionState$ = this.#connectionStateSubject.asObservable();

  constructor(firebaseInitializer: FirebaseInitializer) {
    firebaseInitializer.initializationStatus$.subscribe(state => {
      if (state === FirebaseInitializerStatusesEnum.INITIALIZED) {
        this.#database = getDatabase(getApp())
      }
    })
  }

  subscribeToConnectionChanges() {
    onValue(child(ref(this.#database), '/.info/connected'), snapshot => {
      if (snapshot.val() === true) {
        this.resetConnectionStatus()
      } else {
        this.#connected = false
        this.#disconnectionTimeout = setTimeout(
          this.confirmDisconnection,
          30000,
          this
        )
      }
    })
  }

  resetConnectionStatus() {
    this.#connected = true
    this.#databaseConnection = FirebaseConnectionStatusesEnum.CONNECTED
    this.#connectionStateSubject.next(this.#databaseConnection)
  }

  get online() {
    return this.#databaseConnection === FirebaseConnectionStatusesEnum.CONNECTED
      ? true
      : false
  }

  get offline() {
    return this.#databaseConnection === FirebaseConnectionStatusesEnum.DISCONNECTED
      ? true
      : false
  }

  private confirmDisconnection(client: FirebaseConnectionStatusClient) {
    clearTimeout(client.#disconnectionTimeout)
    if (this.#connected === false) {
      this.#databaseConnection = FirebaseConnectionStatusesEnum.DISCONNECTED
      this.#connectionStateSubject.next(this.#databaseConnection)
    }
  }
}
