import {
  GroupsEnum,
  LanguageCodesEnum,
  DeviceInfo,
} from '../..'

import {
  AgentsEnum,
  EventsEnum,
  EventLogProductsEnum,
  SidesEnum,
  MatchmakingFiltersQuizSubmittedEvent,
  MatchmakingSearchRequestedEvent,
  MatchmakingProfessionalSeenEvent,
  MatchmakingVideoPlayedEvent,
  MatchmakingProfessionalContactedEvent,
  MatchmakingProfessionalFilteredEvent,
  MatchmakingProfessionalListedEvent,
  MatchmakingProfessionalClickedEvent,
  MatchmakingSearchCriteria,
  MatchmakingTeaserSeenEvent,
  MatchmakingTeaserClickedEvent,
  MatchmakingNextPageRequestedEvent,
  MatchmakingProfessionalAddedEvent,
  MatchmakingProfessionalRemovedEvent,
  MatchmakingSearchTypesEnum,
  MatchmakingSearchResultTypesEnum,
  GenderCodesEnum,
  MatchmakingProfessionalDBDocument,
  MatchmakingProfessionalChangedEvent,
} from '..'

export const MatchmakingEventLogFactory = {

  createMatchmakingFiltersQuizSubmittedEvent: (data: {
    uid: string
    gender: GenderCodesEnum
    age: number
    language: LanguageCodesEnum
    device: DeviceInfo
  }): MatchmakingFiltersQuizSubmittedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.MATCHMAKING_FILTERS_QUIZ_SUBMITTED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.MATCHMAKING,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    language: data.language,
    gender: data.gender,
    age: data.age,
    deviceId: data.device.deviceUuid || null,
    os: data.device.os || null,
    osVersion: data.device.osVersion || null,
    platform: data.device.platform || null,
    appVersion: data.device.appVersion || null,
    deviceType: data.device.type || null
  }),

  createMatchmakingSearchRequestedEvent: (data: {
    uid: string
    gender: GenderCodesEnum
    age: number
    language: LanguageCodesEnum
    searchCriteria: MatchmakingSearchCriteria
    searchId: string
    searchType: MatchmakingSearchTypesEnum
  }): MatchmakingSearchRequestedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.MATCHMAKING_SEARCH_REQUESTED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.MATCHMAKING,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    language: data.language,
    gender: data.gender,
    age: data.age,
    problem: data.searchCriteria.problem,
    reason: data.searchCriteria.reason,
    keywords: data.searchCriteria.keywords || data.searchCriteria.name || null,
    orderBy: data.searchCriteria.orderBy,
    profession: data.searchCriteria.profession,
    searchId: data.searchId,
    searchType: data.searchType,
  }),

  createMatchmakingNextPageRequestedEvent: (data: {
    uid: string
    searchId: string
    pageNumber: number
  }): MatchmakingNextPageRequestedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.MATCHMAKING_NEXT_PAGE_REQUESTED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.MATCHMAKING,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    searchId: data.searchId,
    page: data.pageNumber,
  }),

  createMatchmakingProfessionalSeenEvent: (data: {
    uid: string
    gender: GenderCodesEnum
    age: number
    language: LanguageCodesEnum
    professionalId: string
    searchId: string
    page: number
    position: number
    whatsAppAppMissing: boolean
    device: DeviceInfo
  }): MatchmakingProfessionalSeenEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.MATCHMAKING_PROFESSIONAL_SEEN,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.MATCHMAKING,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    language: data.language,
    gender: data.gender,
    age: data.age,
    professionalId: data.professionalId,
    searchId: data.searchId,
    page: data.page,
    position: data.position,
    whatsAppAppMissing: data.whatsAppAppMissing,
    deviceId: data.device.deviceUuid || null,
    os: data.device.os || null,
    osVersion: data.device.osVersion || null,
    platform: data.device.platform || null,
    appVersion: data.device.appVersion || null,
    deviceType: data.device.type || null
  }),

  createMatchmakingVideoPlayedEvent: (data: {
    uid: string
    gender: GenderCodesEnum
    age: number
    language: LanguageCodesEnum
    professionalId: string
    searchId: string
    page: number
    position: number
    device: DeviceInfo
  }): MatchmakingVideoPlayedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.MATCHMAKING_VIDEO_PLAYED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.MATCHMAKING,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    language: data.language,
    gender: data.gender,
    age: data.age,
    professionalId: data.professionalId,
    searchId: data.searchId,
    page: data.page,
    position: data.position,
    deviceId: data.device.deviceUuid || null,
    os: data.device.os || null,
    osVersion: data.device.osVersion || null,
    platform: data.device.platform || null,
    appVersion: data.device.appVersion || null,
    deviceType: data.device.type || null
  }),

  createMatchmakingProfessionalClickedEvent: (data: {
    uid: string
    gender: GenderCodesEnum
    age: number
    language: LanguageCodesEnum
    professionalId: string
    searchId: string
    page: number
    position: number
    device: DeviceInfo
  }): MatchmakingProfessionalClickedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.MATCHMAKING_PROFESSIONAL_CLICKED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.MATCHMAKING,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    language: data.language,
    gender: data.gender,
    age: data.age,
    professionalId: data.professionalId,
    searchId: data.searchId,
    page: data.page,
    position: data.position,
    deviceId: data.device.deviceUuid || null,
    os: data.device.os || null,
    osVersion: data.device.osVersion || null,
    platform: data.device.platform || null,
    appVersion: data.device.appVersion || null,
    deviceType: data.device.type || null
  }),

  createMatchmakingProfessionalContactedEvent: (data: {
    uid: string
    gender: GenderCodesEnum
    age: number
    language: LanguageCodesEnum
    professionalId: string
    searchId: string
    page: number
    position: number
    timestamp: Date
  }): MatchmakingProfessionalContactedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.MATCHMAKING_PROFESSIONAL_CONTACTED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.MATCHMAKING,
    side: SidesEnum.CLIENT_UX,
    timestamp: data.timestamp,
    uid: data.uid,
    language: data.language,
    gender: data.gender,
    age: data.age,
    professionalId: data.professionalId,
    searchId: data.searchId,
    page: data.page,
    position: data.position,
  }),

  createMatchmakingProfessionalFilteredEvent: (data: {
    uid: string
    gender: GenderCodesEnum
    age: number
    language: LanguageCodesEnum
    professionalId: string
    searchId: string
    timestamp: Date
    searchCriteria: MatchmakingSearchCriteria
    searchType: MatchmakingSearchTypesEnum,
    resultType: MatchmakingSearchResultTypesEnum
  }): MatchmakingProfessionalFilteredEvent => ({
    agent: AgentsEnum.SYSTEM,
    event: EventsEnum.MATCHMAKING_PROFESSIONAL_FILTERED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.MATCHMAKING,
    side: SidesEnum.SERVER,
    timestamp: data.timestamp,
    uid: data.uid,
    language: data.language,
    gender: data.gender,
    age: data.age,
    problem: data.searchCriteria.problem,
    reason: data.searchCriteria.reason,
    orderBy: data.searchCriteria.orderBy,
    profession: data.searchCriteria.profession,
    professionalId: data.professionalId,
    searchId: data.searchId,
    searchType: data.searchType,
    resultType: data.resultType,
  }),

  createMatchmakingProfessionalListedEvent: (data: {
    uid: string
    language: LanguageCodesEnum
    professionalId: string
    searchId: string
    page: number
    position: number
    timestamp: Date
  }): MatchmakingProfessionalListedEvent => ({
    agent: AgentsEnum.SYSTEM,
    event: EventsEnum.MATCHMAKING_PROFESSIONAL_LISTED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.MATCHMAKING,
    side: SidesEnum.SERVER,
    timestamp: data.timestamp,
    uid: data.uid,
    language: data.language,
    professionalId: data.professionalId,
    searchId: data.searchId,
    page: data.page,
    position: data.position,
  }),

  createMatchmakingTeaserSeenEvent: (data: {
    uid: string
    gender: GenderCodesEnum
    language: LanguageCodesEnum
    searchId: string
    teaserId: string
    position: number
    device: DeviceInfo
  }): MatchmakingTeaserSeenEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.MATCHMAKING_TEASER_SEEN,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.MATCHMAKING,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    language: data.language,
    gender: data.gender,
    searchId: data.searchId,
    teaserId: data.teaserId,
    position: data.position,
    deviceId: data.device.deviceUuid || null,
    os: data.device.os || null,
    osVersion: data.device.osVersion || null,
    platform: data.device.platform || null,
    appVersion: data.device.appVersion || null,
    deviceType: data.device.type || null
  }),

  createMatchmakingTeaserClickedEvent: (data: {
    uid: string
    gender: GenderCodesEnum
    language: LanguageCodesEnum
    searchId: string
    teaserId: string
    position: number
    device: DeviceInfo
  }): MatchmakingTeaserClickedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.MATCHMAKING_TEASER_CLICKED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.MATCHMAKING,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    language: data.language,
    gender: data.gender,
    searchId: data.searchId,
    teaserId: data.teaserId,
    position: data.position,
    deviceId: data.device.deviceUuid || null,
    os: data.device.os || null,
    osVersion: data.device.osVersion || null,
    platform: data.device.platform || null,
    appVersion: data.device.appVersion || null,
    deviceType: data.device.type || null
  }),

  createMatchmakingProfessionalAddedEvent: (
    professional: MatchmakingProfessionalDBDocument,
    date: Date
  ): MatchmakingProfessionalAddedEvent => ({
    agent: AgentsEnum.SYSTEM,
    event: EventsEnum.MATCHMAKING_PROFESSIONAL_ADDED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.MATCHMAKING,
    side: SidesEnum.SERVER,
    timestamp: date,
    uid: professional.uid,
    professionalId: professional.professionalId,
    approach: professional.approach,
    focus: professional.focus,
    sessionPrice: professional.sessionPrice,
    specialty: professional.specialty,
    targetPublic: professional.targetPublic
  }),

  createMatchmakingProfessionalChangedEvent: (
    professional: MatchmakingProfessionalDBDocument,
    date: Date
  ): MatchmakingProfessionalChangedEvent => ({
    agent: AgentsEnum.SYSTEM,
    event: EventsEnum.MATCHMAKING_PROFESSIONAL_CHANGED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.MATCHMAKING,
    side: SidesEnum.SERVER,
    timestamp: date,
    uid: professional.uid,
    professionalId: professional.professionalId,
    approach: professional.approach,
    focus: professional.focus,
    sessionPrice: professional.sessionPrice,
    specialty: professional.specialty,
    targetPublic: professional.targetPublic
  }),

  createMatchmakingProfessionalRemovedEvent: (data: {
    uid: string
    professionalId: string,
    date: Date
  }): MatchmakingProfessionalRemovedEvent => ({
    agent: AgentsEnum.SYSTEM,
    event: EventsEnum.MATCHMAKING_PROFESSIONAL_REMOVED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.MATCHMAKING,
    side: SidesEnum.SERVER,
    timestamp: data.date,
    uid: data.uid,
    professionalId: data.professionalId,
  }),
}
