import {
  AcceptPatientInvitationRequest,
  CreateUserRequest,
  GetUserViewRequest,
  RefusePatientInvitationRequest,
  SendEmailVerificationActionLinkRequest,
  SendMessageRequest,
  SendVerificationSmsRequest,
  CheckVerificationSmsRequest,
  UpdateUserProfileRequest,
  ArchivePatientRequest,
  GetLaunchContextRequest,
  SuLoginRequest,
} from './request-types'

export enum DashAuthenticatedRequestTypesEnum {

  GET_LAUNCH_CONTEXT = 'get-launch-context',
  CREATE_USER = 'create-user',
  GET_USER_VIEW = 'get-user-view',
  UPDATE_USER_PROFILE = 'update-user-profile',

  SU_LOGIN = 'su-login',

  ACCEPT_PATIENT_INVITATION = 'accept-patient-invitation',
  REFUSE_PATIENT_INVITATION = 'refuse-patient-invitation',
  ARCHIVE_PATIENT = 'archive_patient',
  INVITE_PATIENT = 'invite_patient',

  SEND_MESSAGE = 'send-message',
  SEND_EMAIL_VERIFICATION_ACTION_LINK = 'send-email-verification-action-link',

  SEND_VERIFICATION_SMS = 'send-verification-sms',
  CHECK_VERIFICATION_SMS = 'check-verification-sms',
}

export type DashAuthenticatedCFRequest = {
  type: DashAuthenticatedRequestTypesEnum
  request?: GetLaunchContextRequest
    | CreateUserRequest
    | GetUserViewRequest
    | UpdateUserProfileRequest
    | SuLoginRequest
    | AcceptPatientInvitationRequest
    | RefusePatientInvitationRequest
    | ArchivePatientRequest
    | SendMessageRequest
    | SendEmailVerificationActionLinkRequest
    | SendVerificationSmsRequest
    | CheckVerificationSmsRequest
}
