import { Injectable } from '@angular/core';
import { Credential, MobilePhoneNumber } from 'src/libs';
import { UserService } from './user.service';
import { LoadingService } from '../../../shared';
import { LanguageService } from 'src/app/core';
import { PersonalInformation } from '../types';

@Injectable({
  providedIn: 'root'
})
export class UserUpdateService {

  step: number;
  updateSteps: number;
  updateFlow: string;
  interval: NodeJS.Timeout;

  email: string;
  emailAlreadyExists = false;
  resending = false;
  credential: Credential;
  telephone: MobilePhoneNumber;

  constructor(
    public userService: UserService,
    public languageService: LanguageService,
    private loadingService: LoadingService) {
    this.email = this.userService.email;
    this.credential = { email: this.email };
  }

  setFlow(flowOption: string) {
    if (flowOption === 'complete'){
      this.updateSteps = 3;
      this.step = 1;
      this.updateFlow = 'complete';
    }
    else {
      this.updateSteps = 2;
      this.step = 1;
      this.updateFlow = 'update';
    }
  }

  notify() {
    this.step++;
  }

  async sendVerificationEmail() {
    this.cancelNotificationUponAccountVerification();
    await this.loadingService.present([]);
    await this.sendAccountVerificationEmail(this.email);
    await this.loadingService.dismiss();
  }

  onTelephoneChange(telephone: MobilePhoneNumber) {
    this.telephone = telephone;
    this.step++;
  }

  onTelephoneVerified(telephone: MobilePhoneNumber) {
    this.telephone = telephone;
    this.step++;
  }

  async updateProfileInfo(personalInformation: PersonalInformation) {
    return this.userService.updateProfileInfo(personalInformation, this.telephone);
  }

  get currentLanguage() {
    return this.languageService.currentLanguage;
  }

  async sendAccountVerificationEmail(email: string) {
    this.email = email;
    this.credential = { email: this.email };
    await this.userService.sendAccountVerificationEmail(this.languageService.currentLanguage)
    this.getNotifiedUponAccountVerification();
  }

  async checkUserAccountChanges(userService: UserService, observer: UserUpdateService) {
    const authUser = await userService.reloadCurrentUser();
    if (authUser.emailVerified){
      clearInterval(observer.interval);
      observer.notify();
    }
  }

  getNotifiedUponAccountVerification() {
    this.interval = setInterval(this.checkUserAccountChanges, 2000, this.userService, this);
  }

  cancelNotificationUponAccountVerification() {
    clearInterval(this.interval);
  }

  get mobilePhoneNumber() {
    return this.userService.userIdentity?.mobilePhoneNumber;
  }

  get personalInformation(): PersonalInformation {
    return {
      firstName: this.userService.userIdentity?.firstName,
      lastName: this.userService.userIdentity?.lastName,
      gender: this.userService.userOptions?.gender,
      professionalId: this.userService.userProfessionalInformation?.professionalId,
      professionalInformation: this.userService.userProfessionalInformation?.professionalInformation
    }
  }

  get showPrivacyAndTerms(): boolean {
    return this.userService.userGuards.privacyPolicyVersionAccepted ? false : true;
  }
}
