import {
  GroupsEnum,
} from '../..'

import {
  AgentsEnum,
  EventsEnum,
  EventLogProductsEnum,
  SidesEnum,
  ScheduleInterviewInvitationEvent,
} from '..'

export const ScheduleInterviewEventLogFactory = {

  createInviationSetEvent: (data: {
    uid: string,
    option: number
  }): ScheduleInterviewInvitationEvent => ({
    agent: AgentsEnum.SYSTEM,
    event: EventsEnum.SCHEDULE_INTERVIEW_INVITATION_SET,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.DASHBOARD,
    side: SidesEnum.SERVER,
    timestamp: new Date(),
    uid: data.uid,
    option: data.option
  }),

  createInviationSeenEvent: (data: {
    uid: string,
    option: number
  }): ScheduleInterviewInvitationEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.SCHEDULE_INTERVIEW_INVITATION_SEEN,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.DASHBOARD,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    option: data.option
  }),

  createInviationClickedEvent: (data: {
    uid: string,
    option: number
  }): ScheduleInterviewInvitationEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.SCHEDULE_INTERVIEW_INVITATION_CLICKED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.DASHBOARD,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    option: data.option
  }),

  createInviationRejectedEvent: (data: {
    uid: string,
    option: number
  }): ScheduleInterviewInvitationEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.SCHEDULE_INTERVIEW_INVITATION_REJECTED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.DASHBOARD,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    option: data.option
  }),
}
