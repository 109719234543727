import {
  GroupsEnum,
} from '../..'

import {
  OpsSigninAttemptedEvent,
  OpsSignedOutEvent,
  AgentsEnum,
  EventsEnum,
  EventLogProductsEnum,
  SidesEnum,
  ResultsEnum,
  OpsAuthenticatedEvent,
  OpsPasswordRecoveryAttemptedEvent,
  OpsPasswordResetEvent
} from '..'

export const OpsEventLogFactory = {

  createSignInAttemptEvent: (data: {
    uid?: string,
    email: string,
    errorReason?: string
  }): OpsSigninAttemptedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.SIGNIN_ATTEMPTED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.OPS,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid || null,
    email: data.email.toLowerCase(),
    result: data.errorReason ? ResultsEnum.ERROR : ResultsEnum.OK,
    errorReason: data.errorReason || null
  }),

  createSignOutEvent: (data: {
    uid: string
  }): OpsSignedOutEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.SIGNED_OUT,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.OPS,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid
  }),

  createAuthenticatedEvent: (data: {
    uid: string,
  }): OpsAuthenticatedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.AUTHENTICATED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.OPS,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
  }),

  createPasswordRecoveryAttemptedEvent: (data: {
    email: string,
    errorReason?: string
  }): OpsPasswordRecoveryAttemptedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.PASSWORD_RECOVERY_ATTEMPTED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.OPS,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    email: data.email.toLowerCase(),
    result: data.errorReason ? ResultsEnum.ERROR : ResultsEnum.OK,
    errorReason: data.errorReason || null
  }),

  createPasswordResetEvent: (data: {
    email: string,
  }): OpsPasswordResetEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.PASSWORD_RESET,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.OPS,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    email: data.email.toLowerCase(),
    result: ResultsEnum.OK
  }),
}
