import { assertNonNullish } from '..'

export const formatTimestampAsKey = (date: Date): string => {
  assertNonNullish(date, 'formatTimestampAsKey', 'date')
  return date.toISOString().replace(/[-.:]/g, '')
}

export const formatTimestampAsShortKey = (date: Date): string => {
  assertNonNullish(date, 'formatTimestampAsShortKey', 'date')
  return (date.toISOString().split('T'))[0].replace(/[-]/g, '')
}

export const formatISOStringAsKey = (date: string): string =>
  date.replace(/[-.:]/g, '')

export const capitalizeFirstLetter = (text: string): string => {
  if (text && text.length > 0) {
    const textInLowerCase = text.toLowerCase()
    return textInLowerCase.charAt(0).toUpperCase() + textInLowerCase.slice(1)
  }
  return text
}

export const capitalizeFirstLetterOfEachWord = (text: string): string => {
  if (text && text.length > 0) {
    return text
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
  }
  return text
}

export const formatDateAsYMD = (date: Date): string => {
  assertNonNullish(date, 'formatDateAsYMD', 'date')
  return date.toISOString().split('T')[0]
}

export const yearForCopyright = (): string => {
  const now = new Date()
  return `${now.getUTCFullYear()}`
}

export const formatPushIdAsKey = (id: string): string => id.replace(/[:@]/g, '')

export const fromISOStringToDateByDocument = (
  document: Record<string, unknown>,
  pattern: string
) => {
  if (document) {
    for (const field in document) {
      if (field.indexOf(pattern) >= 0 && document[field]) {
        document[field] = new Date(String(document[field]))
      }
    }
  }
}

export const fromDateToISOStringByDocument = (document: any, pattern: string) => {
  if (document) {
    for (const field in document) {
      if (field.indexOf(pattern) >= 0 && document[field]) {
        document[field] = (document[field] as Date).toISOString()
      }
    }
  }
}
