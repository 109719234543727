import { Injectable } from '@angular/core'
import { CloudFunctionsClient, DatabaseResponse, UnauthenticatedCFRequest, DashAuthenticatedCFRequest } from 'src/libs'

@Injectable({
  providedIn: 'root'
})
export class CloudFunctionsService {

  constructor(private functionsClient: CloudFunctionsClient) { }

  async dashAuthenticatedCall(
    request: DashAuthenticatedCFRequest
  ): Promise<DatabaseResponse> {
    return this.functionsClient.call('handleDashAuthenticatedRequest', request)
  }

  async unauthenticatedCall(
    request: UnauthenticatedCFRequest
  ): Promise<DatabaseResponse> {
    return this.functionsClient.call('handleUnauthenticatedRequest', request)
  }
}
