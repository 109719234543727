import { Injectable } from '@angular/core';
import {
  Credential,
  StatusEnum,
  MobilePhoneNumber,
  ProductsEnum,
  LanguageCodesEnum,
  DeviceInfoDBDocument,
} from 'src/libs';

import {
  UnauthenticatedRequestTypesEnum,
  CreateDashAccountRequest
} from 'src/libs';
import { CloudFunctionsService, DeviceService, LanguageService, LocalStorageService } from 'src/app/core';
import { UserService } from './user.service';
import { LoadingService } from '../../../shared';
import { PersonalInformation } from '../types';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserSignupService {

  step = 1;
  interval: NodeJS.Timeout;

  email: string;
  emailAlreadyExists = false;
  resending = false;
  credential: Credential;
  telephone: MobilePhoneNumber;

  constructor(
    public userService: UserService,
    public languageService: LanguageService,
    private functionsService: CloudFunctionsService,
    private localStorageService: LocalStorageService,
    private deviceService: DeviceService,
    private loadingService: LoadingService) { }

  notify() {
    if (this.credential.rememberMe) {
      this.localStorageService.saveEmailValue(this.credential.email);
    }
    this.step++;
  }

  async signUp(credential: Credential) {
    await this.localStorageService.clearStorage();
    await this.localStorageService.saveLanguageValue(this.languageService.currentLanguage);
    const result = await this.createAccount(credential);
    if (result.status === StatusEnum.OK){
      this.credential = credential;
    }
    return result;
  }

  async resendVerificationEmail(email: string) {
    this.cancelNotificationUponAccountVerification();
    if (email === this.credential.email) {
      await this.loadingService.present([]);
      this.sendAccountVerificationEmail(email);
      await this.loadingService.dismiss();
    }
    else {
      this.signUp({ email: email, password: this.credential.password });
    }
  }

  private async createAccount(credential: Credential) { // TODO add wrongEmail to request
    this.email = credential.email.toLowerCase();
    const device: DeviceInfoDBDocument = { ...this.deviceService.device, updatedAtDate: new Date() };
    const request: CreateDashAccountRequest = {
      callerId: ProductsEnum.COGNI_DASHBOARD,
      email: this.email,
      password: credential.password,
      language: this.languageService.currentLanguage as LanguageCodesEnum,
      dashTermsVersionAccepted: '2021-10-15T00:00:00.000Z', // TODO make non-static
      privacyPolicyVersionAccepted: '2021-10-15T00:00:00.000Z', // TODO make non-static
      device,
      continueUrl: `${environment.urls.rootUrl}`,
    };
    return this.functionsService.unauthenticatedCall({
      type: UnauthenticatedRequestTypesEnum.CREATE_ACCOUNT,
      request: request
    });
  }

  async createUser(personalInformation: PersonalInformation) {
    return this.userService.createUserRequest(personalInformation, this.telephone);
  }

  onTelephoneChange(telephone: MobilePhoneNumber) {
    this.telephone = telephone;
    this.step++;
  }

  get currentLanguage() {
    return this.languageService.currentLanguage;
  }

  getNotifiedUponAccountVerification() {
    this.interval = setInterval(this.checkUserAccountChanges, 2000, this.userService, this);
  }

  cancelNotificationUponAccountVerification() {
    clearInterval(this.interval);
  }

  async checkUserAccountChanges(userService: UserService, observer: UserSignupService) {
    const authUser = await userService.reloadCurrentUser();
    if (authUser.emailVerified){
      clearInterval(observer.interval);
      observer.notify();
    }
  }

  async sendAccountVerificationEmail(email: string) {
    this.email = email;
    const result = await this.userService.sendAccountVerificationEmail(this.languageService.currentLanguage)
    if (result.status === StatusEnum.OK) {
      this.getNotifiedUponAccountVerification();
    }
    else {
      // TODO show error
    }
  }
}
