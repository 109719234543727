import { ErrorsEnum, StatusEnum, BaseResponse, Error, ErrorDescription, LoggerInterface } from '..'

const throwError = (error: ErrorDescription) => {
  throw new Error(JSON.stringify({ errorDescription: error }))
}

const processError = (error: any, logger: LoggerInterface): BaseResponse => {
  try {
    const response: Error = JSON.parse(error.message)
    logger.trace('processError', error, response)
    if (response.errorDescription) {
      return {
        status: StatusEnum.ERROR,
        ...response.errorDescription
      }
    }
  } catch (err) {
    // Failed decoding error, let's treat it as a standard error
  }
  logger.error('processError', error)
  return {
    status: StatusEnum.ERROR,
    error: ErrorsEnum.UNKNOWN,
    message: error.message
  }
}

export { processError, throwError }
