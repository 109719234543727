import { Injectable } from '@angular/core';
import { AuthClient, SendPasswordResetActionLinkRequest, SendPasswordResetActionLinkResponse, UnauthenticatedRequestTypesEnum } from 'src/libs';
import { ConfigService, LanguageService } from '.';
import { CloudFunctionsService } from '..';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  constructor(private authClient: AuthClient,
    private functionsService: CloudFunctionsService,
    private languageService: LanguageService) { }

  sendPasswordResetRequest(email: string): Promise<SendPasswordResetActionLinkResponse> {
    const request: SendPasswordResetActionLinkRequest = {
      callerId: ConfigService.getProductsEnum(),
      languageCode: this.languageService.currentLanguage,
      email,
      url: 'https://localhost:8100' // TODO
    };
    return this.functionsService.unauthenticatedCall({
      type: UnauthenticatedRequestTypesEnum.SEND_PASSWORD_RESET_ACTION_LINK,
      request
    });
  }

  async verifyPasswordResetCode(oobCode: string) {
    return this.authClient.verifyPasswordResetCode(oobCode);
  }

  async confirmPasswordReset(oobCode: string, newPassword: string) {
    return this.authClient.confirmPasswordReset(oobCode, newPassword);
  }
}
