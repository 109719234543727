export enum PatientTypesEnum {
  INVITATION = 'invitation',
  PATIENT = 'patient'
}

export enum PatientModelStatusesEnum {
  INVITATION_PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  REINVITATION_PENDING = 'REINVITATION-PENDING',
  ARCHIVED = 'ARCHIVED'
}

export enum ViewOrderEnum {
  SUICIDE_ALERT = '1_SUICIDE_ALERT',
  INVITATION_PENDING = '2_INVITATION_PENDING',
  HAVE_NOTIFICATIONS = '3_HAVE_NOTIFICATIONS',
  DEFAULT = '4_DEFAULT',
  REINVITATION_PENDING = '5_REINVITATION_PENDING',
  ARCHIVED = '6_ARCHIVED'
}
