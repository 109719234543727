import {
  GroupsEnum,
  DeviceInfo,
} from '../..'

import {
  AgentsEnum,
  EventsEnum,
  EventLogProductsEnum,
  SidesEnum,
  ModuleOpenedEvent,
  ScaleOpenedEvent,
  ModuleAccessAttemptedEvent,
  TrialGrantedEvent
} from '..'

export const PsyeduEventLogFactory = {

  createModuleOpenedEvent: (data: {
    uid: string,
    moduleId: number
    device: DeviceInfo
  }): ModuleOpenedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.MODULE_OPENED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.PSYCHOEDUCATION,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    id: data.moduleId,
    deviceId: data.device.deviceUuid,
    os: data.device.os,
    osVersion: data.device.osVersion,
    platform: data.device.platform,
    appVersion: data.device.appVersion || null,
    deviceType: data.device.type,
  }),

  createScaleOpenedEvent: (data: {
    uid: string,
    scaleId: string
    device: DeviceInfo
  }): ScaleOpenedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.SCALE_OPENED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.PSYCHOEDUCATION,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    id: data.scaleId,
    deviceId: data.device.deviceUuid,
    os: data.device.os,
    osVersion: data.device.osVersion,
    platform: data.device.platform,
    appVersion: data.device.appVersion || null,
    deviceType: data.device.type,
  }),

  createModuleAccessAttemptedEvent: (data: {
    uid: string,
    moduleId: number
    device: DeviceInfo
  }): ModuleAccessAttemptedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.MODULE_ACCESS_ATTEMPTED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.PSYCHOEDUCATION,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    id: data.moduleId,
    deviceId: data.device.deviceUuid,
    os: data.device.os,
    osVersion: data.device.osVersion,
    platform: data.device.platform,
    appVersion: data.device.appVersion || null,
    deviceType: data.device.type,
  }),

  createTrialGrantedEvent: (data: {
    uid: string
  }): TrialGrantedEvent => ({
    agent: AgentsEnum.SYSTEM,
    event: EventsEnum.TRIAL_GRANTED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.PSYCHOEDUCATION,
    side: SidesEnum.SERVER,
    timestamp: new Date(),
    uid: data.uid
  }),
}
