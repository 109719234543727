import { GenderCodesEnum, LanguageCodesEnum, DashUserView } from 'src/libs';
import { UserGuardsModel, UserIdentityModel, UserOptionsModel, UserProfessionalInformationModel } from '../types';

export const DashUserViewFactory = {

  createUserIdentity: (data: {
    uid: string,
    email: string,
    displayName: string,
    cognicode: string    
  }): UserIdentityModel => ({
    uid: data.uid,
    email: data.email,
    firstName: data.displayName ? data.displayName.split('%%')[0] : null,
    lastName: data.displayName ? data.displayName.split('%%')[1] : null,
    cognicode: data.cognicode || null,
    mobilePhoneNumber: null
  }),

  reconstituteUserIdentity: (
    current: UserIdentityModel,
    document: DashUserView
  ): UserIdentityModel | undefined => {
    if (document) {
      return {
        ...current,
        firstName: document.firstName,
        lastName: document.lastName,
        cognicode: document.cognicode,
        mobilePhoneNumber: document.mobilePhoneNumber
      }
    }
    return undefined
  },

  reconstituteProfessionalInformation: (
    document: DashUserView
  ): UserProfessionalInformationModel | undefined => {
    if (document) {
      return {
        professionalId: document.professionalId,
        professionalInformation: document.professionalInformation
      }
    }
    return undefined
  },

  reconstituteUserOptions: (
    document: DashUserView
  ): UserOptionsModel | undefined => {
    if (document) {
      return {
        gender: document.gender || GenderCodesEnum.UNKNOWN,
        language: document.language || LanguageCodesEnum.UNKNOWN
      }
    }
    return undefined
  },

  reconstituteUserGuards: (
    document: DashUserView
  ): UserGuardsModel | undefined => {
    if (document) {
      return {
        dashTermsVersionAccepted: document.dashTermsVersionAccepted,
        privacyPolicyVersionAccepted: document.privacyPolicyVersionAccepted
      }
    }
    return undefined
  },
}