import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  AuthClient,
  CustomClaims,
  DashboardSubscriptionTypesEnum,
  FirestoreClient,
  PaymentStatusesEnum,
  SubscriptionFailuresEnum,
} from '../../../../libs';
import { CollectionReference } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  #type = DashboardSubscriptionTypesEnum.FREEMIUM;
  #paymentStatus = PaymentStatusesEnum.NONE;
  #failure: SubscriptionFailuresEnum;
  #subscriptionIssue: boolean;
  #subscriptionId: string;
  #paymentLink: string;

  private subscriptionIssueSubject = new BehaviorSubject<boolean>(undefined);
  public readonly subscriptionIssue$ = this.subscriptionIssueSubject.asObservable();

  constructor(
    private authClient: AuthClient,
    private firestore: FirestoreClient,
  ) { }

  async setSubscriptionState() {
    this.#type = (this.authClient.customClaims as CustomClaims)?.dashSubsType || DashboardSubscriptionTypesEnum.FREEMIUM;
    this.#paymentStatus = (this.authClient.customClaims as CustomClaims)?.dashPayStatus;
    this.#failure = (this.authClient.customClaims as CustomClaims)?.dashSubsFailure;
    this.#subscriptionId = (this.authClient.customClaims as CustomClaims)?.dashSubsId;

    if (this.#paymentStatus === PaymentStatusesEnum.OVERDUE
      //|| this.paymentStatus === PaymentStatusesEnum.NEAR_SUSPENSION
      || this.paymentStatus === PaymentStatusesEnum.SUSPENDED
      || this.paymentStatus === PaymentStatusesEnum.BLOCKED) {
      this.#subscriptionIssue = true;
    } else {
      this.#subscriptionIssue = false;
    }
    this.subscriptionIssueSubject.next(this.#subscriptionIssue);
    if (this.#subscriptionIssue) {
      await this.getSubscriptionInfo();
    }
  }

  get isSubscriber() {
    return this.subscriptionType === DashboardSubscriptionTypesEnum.BASIC || this.subscriptionType === DashboardSubscriptionTypesEnum.STUDENT;
  }

  get subscriptionType() {
    return this.#type;
  }

  get paymentStatus() {
    return this.#paymentStatus;
  }

  get subscriptionIssue() {
    return this.#subscriptionIssue;
  }

  get subscriptionFailure() {
    return this.#failure;
  }

  get paymentLink() {
    return this.#paymentLink;
  }

  private async getSubscriptionInfo() {
    const subscriptionInfo = await this.firestore.getDoc(this.firestore.docRef(this.subscriptionsCollRef(), this.#subscriptionId));
    this.#paymentLink = subscriptionInfo.stripePaymentLink;
  }

  private subscriptionsCollRef(): CollectionReference {
    return this.firestore.collRef(this.subscriptionsCollId);
  }

  private subscriptionsCollId = '/subscriptions';
}
