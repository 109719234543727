import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(private storage: Storage) { }

  initializeStorage() {
    return this.storage.create();
  }

  saveLanguageValue(language: string) {
    return this.saveStringValue(this.languageKey, language);
  }
  readLanguageValue() {
    return this.readStringValue(this.languageKey);
  }

  saveChartToggleState(state: boolean) {
    return this.saveBooleanValue(this.chartToggleKey, state);
  }
  readChartToggleState() {
    return this.readBooleanValue(this.chartToggleKey);
  }

  saveEmailValue(email: string) {
    return this.saveStringValue(this.emailKey, email);
  }
  readEmailValue() {
    return this.readStringValue(this.emailKey);
  }
  clearEmailValue() {
    return this.remove(this.emailKey);
  }

  saveManifestoControl(dates: string[]) {
    return this.saveStringValue(this.manifestoControl, dates.join(';'));
  }
  async readManifestoControl(): Promise<string[]> {
    const control = await this.readStringValue(this.manifestoControl);
    return control?.split(';') || [];
  }

  saveMatchmakingMockControl(dates: string[]) {
    return this.saveStringValue(this.matchmakingMockControl, dates.join(';'));
  }
  async readMatchmakingMockControl(): Promise<string[]> {
    const control = await this.readStringValue(this.matchmakingMockControl);
    return control?.split(';') || [];
  } 

  saveBannerOption(option: number) {
    return this.saveNumberValue(this.bannerOption, option);
  }
  readBannerOption() {
    return this.readNumberValue(this.bannerOption);
  }

  async clearStorage() {
    await this.storage.clear();
  }

  private saveStringValue(key: string, value: string) {
    return this.write(key, value);
  }

  private readStringValue(key: string): Promise<string> {
    return this.read(key);
  }

  private saveDateValue(key: string, value: Date) {
    return this.write(key, value);
  }

  private async readDateValue(key: string): Promise<Date> {
    return new Date(String(await this.read(key)));
  }

  private saveNumberValue(key: string, value: number) {
    return this.write(key, value);
  }

  private readNumberValue(key: string): Promise<number> {
    return this.read(key);
  }

  private saveBooleanValue(key: string, value: boolean) {
    return this.write(key, value);
  }

  private readBooleanValue(key: string): Promise<boolean> {
    return this.read(key);
  }

  private saveMap(key: string, attributes: any) {
    return this.write(key, attributes);
  }

  private async readMap(key: string): Promise<any> {
    const data: Record<string, unknown> = await this.read(key);
    if (data) {
      for (const field in data) {
        if (field.indexOf('AtDate') >= 0 && data[field]) {
          data[field] = new Date(String(data[field]));
        }
      }
    }
    return data;
  }

  private read(key: string) {
    return this.storage.get(key);
  }

  private write(key: string, value: any) {
    return this.storage.set(key, value);
  }

  private remove(key: string) {
    return this.storage.remove(key);
  }

  private emailKey = 'email';
  private languageKey = 'language';
  private chartToggleKey = 'chartToggle';
  private bannerOption = 'bannerOption';
  private manifestoControl = 'manifestoControl';
  private matchmakingMockControl = 'matchmakingMockControl';
}

