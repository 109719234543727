import { Injectable } from '@angular/core';
import { FirestoreClient, formatTimestampAsKey, getDaysInFuture } from 'src/libs';


@Injectable({
  providedIn: 'root'
})
export class DebugLogService {
  private appNameForLog = 'cogniapp';

  constructor(private firestore: FirestoreClient) { }
  
  logDebug(uid: string, details: any) {
    const attributes = { l: 1, d: details, t: new Date(), expiration: getDaysInFuture(10) };
    return this.insertDebugLog(uid, this.appNameForLog, attributes);
  }

  logInfo(uid: string, details: any) {
    const attributes = { l: 2, d: details, t: new Date(), expiration: getDaysInFuture(10) };
    return this.insertDebugLog(uid, this.appNameForLog, attributes);
  }

  logWarning(uid: string, details: any) {
    const attributes = { l: 3, d: details, t: new Date(), expiration: getDaysInFuture(10) };
    return this.insertDebugLog(uid, this.appNameForLog, attributes);
  }

  logError(uid: string, details: any) {
    const attributes = { l: 4, d: details, t: new Date(), expiration: getDaysInFuture(10) };
    return this.insertDebugLog(uid, this.appNameForLog, attributes);
  }

  logCritical(uid: string, details: any) {
    const attributes = { l: 5, d: details, t: new Date(), expiration: getDaysInFuture(10) };
    return this.insertDebugLog(uid, this.appNameForLog, attributes);
  }
  
  private insertDebugLog(uid: string, appNameForLog: string, attributes: any) {
    const key = formatTimestampAsKey(new Date())
    return this.firestore.insertDoc(
      this.getDebugLogsCollRef(uid, appNameForLog),
      key,
      attributes
    )
  }

  private getDebugLogsCollRef(uid: string, appNameForLog: string) {
    return this.firestore.collRef(`${this.debugLogsCollId}/${uid}/${appNameForLog}-dlogs`)
  }
  
  private debugLogsCollId = '/debugLogs'
}
