import {
  CreateAccountRequest,
  GetAppFormOptionsRequest,
  GetHttpRedirectionRequest,
  GetPublicLaunchContextRequest,
  SendPasswordResetActionLinkResponse,
  ValidateCustomActionLinkRequest
} from './request-types'

export enum UnauthenticatedRequestTypesEnum {
  GET_PUBLIC_LAUNCH_CONTEXT = 'get-public-launch-context',
  CREATE_ACCOUNT = 'create-account',
  SEND_PASSWORD_RESET_ACTION_LINK = 'send-password-reset-action-link',
  GET_HTTP_REDIRECTION = 'get-http-redirection',
  VALIDATE_CUSTOM_ACTION_LINK = 'validate-custom-action-link',
  MATCHMAKING_APP_FORM_OPTIONS = 'matchmaking-app-form-options',
}

export type UnauthenticatedCFRequest = {
  type: UnauthenticatedRequestTypesEnum
  request?: GetPublicLaunchContextRequest
    | CreateAccountRequest
    | GetHttpRedirectionRequest
    | SendPasswordResetActionLinkResponse
    | ValidateCustomActionLinkRequest
    | GetAppFormOptionsRequest
}