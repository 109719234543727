export const pageLinks = {
    coverScreen: '/cover-screen',
    main: '/app/features/main',
    patientPage: '/app/features/main/patient',
    profileUpdate: '/auth/update',
    //terms: '/auth/terms-and-policy/terms',
    //policy: '/auth/terms-and-policy/policy',
    passwordRecoveryRequest: '/pwdrecovery/recovery',
    passwordRecoveryConfirmation: '/pwdrecovery/confirmation',
    passwordResetForm: '/pwdreset/reset',
    passwordResetSuccess: '/pwdreset/success',
    passwordResetLinkExpired: '/pwdreset/link-expired',
    emailVerificationSuccess: '/email-verification/success',
    talktous: '/talktous',
    billingSupport: '/auth/billing-support',
    sales: '/sales',
    signup: '/signup',
    matchSales: '/matchsales',
    match: '/match',
}
