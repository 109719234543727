export enum FirebaseConnectionStatusesEnum {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED'
}

export enum AuthStatusesEnum {
  AUTHENTICATED = 'AUTHENTICATED',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  UNKNOWN = 'UNKNOWN'
}

export enum ScreenWidthsEnum {
  XS = '1-extra-small',
  SM = '2-small',
  MD = '3-medium',
  LG = '4-large',
  XL = '5-extra-large'
}