import { PlatformsEnum } from 'src/libs';
import { environment } from 'src/environments/environment';

export class ConfigService {

  private static environmentConfig = environment;

  private static readonly domainConfigs = {
    ThoughtFormatVersion: 2
  };

  static getThoughtFormatVersion(): number {
    return ConfigService.domainConfigs.ThoughtFormatVersion;
  }

  static getProductsEnum() {
    return ConfigService.environmentConfig.domainConfigs.productsEnum;
  }

  static getEventLogProductsEnum() {
    return ConfigService.environmentConfig.domainConfigs.eventLogProductsEnum;
  }

  static getConfig(id: string): any {
    return ConfigService.environmentConfig[id];
  }

  static getFirebase() {
    return ConfigService.environmentConfig.firebase;
  }

  static isProduction(): boolean {
    return ConfigService.environmentConfig.production;
  }
}
