import { deleteApp, getApp, initializeApp } from 'firebase/app'
import { indexedDBLocalPersistence, initializeAuth } from 'firebase/auth'
import { enableIndexedDbPersistence, getFirestore } from 'firebase/firestore'
import { BehaviorSubject } from 'rxjs'

import { Logger } from '..'

export enum FirebaseInitializerStatusesEnum {
  PENDING = 'pending',
  INITIALIZED = 'initialized'
}

export class FirebaseInitializer {

  public initializationStatus$ = new BehaviorSubject<FirebaseInitializerStatusesEnum>(
    FirebaseInitializerStatusesEnum.PENDING
  )

  initialize(firebaseConfig: any) {
    if (firebaseConfig === undefined) {
      throw new Error('nullish-parameter')
    } else if (firebaseConfig === null) {
      throw new Error('nullish-parameter')
    } else if (firebaseConfig === '') {
      throw new Error('nullish-parameter')
    }
    try {
      const app = initializeApp(firebaseConfig)
      initializeAuth(app, {
        persistence: indexedDBLocalPersistence
      })
      if (firebaseConfig.enablePersistenceForFirestore) {
        const firetore = getFirestore(getApp())
        enableIndexedDbPersistence(firetore).catch(err => {
          if (err.code === 'failed-precondition') {
            // Multiple tabs open, persistence can only be enabled in one tab at a a time.
            console.error('unable to enable persistence for firestore, code: failed-precondition')
          } else if (err.code === 'unimplemented') {
            // The current browser does not support all of the features required to enable persistence
            console.error('unable to enable persistence for firestore, code: unimplemented')
          }
        })
      }
      this.initializationStatus$.next(
        FirebaseInitializerStatusesEnum.INITIALIZED
      )
    } catch (error) {
      Logger.error(error)
      throw error
    }
  }

  async destroy() {
    try {
      const app = getApp()
      if (app) {
        await deleteApp(app)
      }
    } catch (error) {
      Logger.error(error)
      throw error
    }
  }
}
