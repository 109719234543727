export enum GroupsEnum {
  COGNI = 'cogni',
  COMPANY = 'company'
}

export enum EducationLevelCodesEnum {
  UNDERGRADUATE = 'UNDERGRADUATE',
  GRADUATE = 'GRADUATE',
  POSTGRADUATE = 'POSTGRADUATE',
  DOCTORATE = 'DOCTORATE',
  OTHER = 'OTHER',
  UNKNOWN = 'unknown',
  PRIMARY_EDUCATION = 'PRIMARY-EDUCATION',
  HIGHER_EDUCATION = 'HIGHER-EDUCATION'
}

// lowercase to match OS/browser locales
export enum LanguageCodesEnum {
  PT_BR = 'pt-BR',
  EN_US = 'en-US',
  /**
   * For compatibility only. DO NOT use for new users.
   */
  UNKNOWN = 'unknown'
}

// lowercase for historical reasons
export enum ProductsEnum {
  COGNI_APP = 'cogni-app',
  COGNI_DASHBOARD = 'cogni-dashboard',
  OPS = 'ops',
  WEBSITE = 'website'
}

/**
 * Refers to biological and physiological characteristics.
 */
export enum SexCodesEnum {
  FEMALE = 'FEMALE',
  MALE = 'MALE'
}

/**
 * Encompasses a person's identities, expressions, and societal roles.
 * In Cogni, gender is used for localization purposes, according to
 * user's answer to the question 'how do you want to be referred to?'.
 */
export enum GenderCodesEnum {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  UNKNOWN = 'unknown'
}

/**
 * Encompasses a person's identities, expressions, and societal roles.
 * In Cogni, gender is used for localization purposes, according to
 * user's answer to the question 'how do you want to be referred to?'.
 * For use by the AvailableTherapist list
 */
export enum AbridgedGenderCodesEnum {
  FEMALE = 'F',
  MALE = 'M',
  UNKNOWN = 'U'
}

export enum MessageFormatsEnum {
  DATA = 'DATA',
  NOTIFICATION = 'NOTIFICATION'
}

export enum MessageTypesEnum {
  OTC = 'otc'
}

export enum MessageMethodsEnum {
  SMS = 'sms',
  WHATSAPP = 'whatsapp'
}

export enum ActionLinkModesEnum {
  VERIFY_EMAIL = 'verifyEmail',
  RESET_PASSWORD = 'resetPassword',
  SIGN_IN = 'signIn',
  CUSTOM_SIGN_IN = 'customSignIn'
}

export enum ConnectionStatusesEnum {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED'
}

export enum PlatformsEnum {
  IOS = 'ios',
  ANDROID = 'android',
  WEB = 'web'
}

export enum OperatingSystemsEnum {
  IOS = 'ios',
  ANDROID = 'android',
  WINDOWS = 'windows',
  MAC = 'mac',
  UNKNOWN = 'unknown'
}

export enum DeviceTypesEnum {
  PHONE = 'phone',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
  UNKNOWN = 'unknown'
}

export enum EmailProviderCodesEnum {
  SENDGRID = 'SENDGRID'
}

export enum EmailStatusesEnum {
  PENDING = 'PENDING',
  HANDED_OVER_TO_PROVIDER = 'HAND-OVER-TO-PROVIDER',
  SENT = 'SENT',
  DELIVERED = 'DELIVERED',
  OPENED = 'OPEN',
  CLICKED = 'CLICKED',
  ISSUE = 'ISSUE'
  //UNKNOWN_RECIPIENT = 'UNKNOWN-RECIPIENT' // TODO
  //MAILBOX_FULL = 'MAILBOX-FULL', //TODO
  //BLOCKED = 'BLOCKED', //TODO
}

export enum ShiftsEnum {
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
  NIGHT = 'NIGHT'
}

export enum InvitationSourcesEnum {
  PATIENT = 'PATIENT',
  THERAPIST = 'THERAPIST'
}

export enum ProfessionCodesEnum {
  PSYCHIATRY = 'PSYCHIATRY',
  PSYCHOLOGY = 'PSYCHOLOGY',
  NEUROLOGY = 'NEUROLOGY',
  NEUROPSYCHIATRY = 'NEUROPSYCHIATRY',
  NEUROPSYCHOLOGY = 'NEUROPSYCHOLOGY',
  COACHING = 'COACHING',
  OTHER = 'OTHER',
  UNKNOWN = 'unknown'
}

export enum ProfessionalIdTypesEnum {
  BRAZIL_CRM = 'CRM'
}

export enum CognitiveDistortionsEnum {
  ALL_OR_NOTHING = 'ALL_OR_NOTHING',
  CATASTROPHIZING = 'CATASTROPHIZING',
  DISCOUNTING_THE_POSITIVE = 'DISCOUNTING_THE_POSITIVE',
  EMOTIONAL_REASONING = 'EMOTIONAL_REASONING',
  LABELING = 'LABELING',
  MAGNIFICATION_VS_MINIMIZATION = 'MAGNIFICATION_VS_MINIMIZATION',
  MENTAL_FILTERING = 'MENTAL_FILTERING',
  MIND_READING = 'MIND_READING',
  OVERGENERALIZATION = 'OVERGENERALIZATION',
  PERSONALIZATION = 'PERSONALIZATION',
  SHOULD_HAVE_STATEMENTS = 'SHOULD_HAVE_STATEMENTS',
  TUNNEL_VISION = 'TUNNEL_VISION'
}

export enum PatientStatusesEnum {
  ACTIVE = 'ACTIVE',
  REINVITATION_PENDING = 'REINVITATION-PENDING',
  ARCHIVED = 'ARCHIVED'
}

/*export enum SourcePrioritiesEnum {
  SUICIDE_ALERT = 9,
  NEW_EVENT = 6,
  ACTIVE = 3,
  NONE = 0
}*/

export enum ArchiveReasonsEnum {
  THERAPIST = 'therapist',
  PATIENT = 'patient',
}

export enum TherapistStatusesEnum {
  ACTIVE = 'ACTIVE',
  TERMINATED = 'TERMINATED', // when therapist deleted the account
  ARCHIVED_BY_THERAPIST = 'ARCHIVED-BY-THERAPIST'
}

export enum ProfessionCodeNumbersEnum {
  PSYCHIATRY = 1,
  PSYCHOLOGY = 2,
  NEUROLOGY = 3,
  NEUROPSYCHIATRY = 4,
  NEUROPSYCHOLOGY = 5,
  COACHING = 6,
  OTHER = 98,
  UNKNOWN = 0
}

export enum AppOnboardingCodesEnum {
  NOT_INITIATED = 0,
  FIRST_DTR_CREATED = 2,
  MATCHMAKING_PRIMING_SEEN = 3
}

export enum ScalesIdsEnum {
  PANAS = 'panas',
  ESV = 'esv',
  EAR = 'ear'
}

export enum ScaleSetsEnum {
  INITIAL = 'INITIAL',
  PANAS = 'PANAS',
  ESV_AND_EAR = 'ESV-AND-EAR'
}

export enum PsychoeducationEnrollmentStatusesEnum {
  REGISTERED = 'REGISTERED',
  ENROLLED = 'ENROLLED',
  ONBOARDED = 'ONBOARDED'
}

export enum MinicardIdsEnum {
  DISCOVER_COGNI = 'dc',
  DISCOVER_DASHBOARD = 'dd',
  GOT_THERAPIST = 'gt',
  JUST_TESTING = 'jt',
  QUIZ = 'qz',
  REMINDER = 'rr',
  SHARE = 'sr',
  MATCHMAKING = 'mm'
}

export enum SurveyTypesEnum {
  MHS_NPS = 'MHS-NPS',
}

export enum CogniTeamRolesEnum {
  ADMIN = 'ADMIN',
  DEVELOPER = 'DEVELOPER',
  SUPPORT = 'SUPPORT',
}

export enum AuthenticationProviderEnum {
  UNKNOWN = 'UNKNOWN',
  INTERNAL = 'INTERNAL',
  GOOGLE = 'GOOGLE',
  APPLE = 'APPLE',
}
