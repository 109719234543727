export class Logger {
  static #enabled = false

  static enableLogger() {
    Logger.#enabled = true
  }

  static disableLogger() {
    Logger.#enabled = false
  }

  static debug(...log: any[]) {
    if (Logger.#enabled) {
      console.debug(log)
    }
  }

  static info(...log: any[]) {
    if (Logger.#enabled) {
      console.info(log)
    }
  }

  static warning(...log: any[]) {
    if (Logger.#enabled) {
      console.warn(log)
    }
  }

  static error(...log: any[]) {
    if (Logger.#enabled) {
      console.error(log)
    }
  }
}
