import { PatientStatusesEnum } from "src/libs"
import { PatientModelStatusesEnum, ViewOrderEnum } from "../enums"

export const convertPatientStatus = (
  patientStatus: PatientStatusesEnum
): PatientModelStatusesEnum => {
  switch (patientStatus) {
    case PatientStatusesEnum.ARCHIVED:
      return PatientModelStatusesEnum.ARCHIVED;
    case PatientStatusesEnum.REINVITATION_PENDING:
      return PatientModelStatusesEnum.REINVITATION_PENDING;
    default:
      return PatientModelStatusesEnum.ACTIVE;
  }
}

export const convertPatientStatusToViewOrder = (
  patientStatus: PatientStatusesEnum
): ViewOrderEnum => {
  switch (patientStatus) {
    case PatientStatusesEnum.ARCHIVED:
      return ViewOrderEnum.ARCHIVED;
    case PatientStatusesEnum.REINVITATION_PENDING:
      return ViewOrderEnum.REINVITATION_PENDING;
    default:
      return ViewOrderEnum.DEFAULT;
  }
}