import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { LanguageService } from 'src/app/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor(
    private loadingController: LoadingController,
    private languageService: LanguageService) { }

  async present(messages: string[], boxed: boolean = false, showBackdrop: boolean = true) {
    let translatedMessage = '';
    for (const message of messages) {
      translatedMessage = [translatedMessage, this.languageService.translate(message)].join(' ');
    }
    const loading = await this.loadingController.create({
      cssClass: boxed ? '' : 'cogni-loading',
      message: translatedMessage,
      spinner: 'circles',
      showBackdrop
    });
    await loading.present();
  }

  async dismiss(){
    return this.loadingController.dismiss();
  }
}
