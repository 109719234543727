import { LanguageCodesEnum } from 'src/libs';
import { SupportedLocale } from '..';
import { enUS, ptBR } from 'date-fns/locale';

export const supportedLocales: SupportedLocale[] = [
  {
    code: LanguageCodesEnum.EN_US,
    defaultFor: 'en',
    dateFns: enUS,
    calendarDateFormat: 'MM/dd/yyyy',
    shortDateFormat: 'MMM-d',
    longDateFormat: 'MMM-dd-yyyy',
    timeFormat: 'h:mm aaa',
    datetimeFormat: 'MMM-dd-yyyy hh:mm a',
    dayNamesForCalendar: ['S','M','T','W','T','F','S'],
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthShortNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    name: 'SETTINGS.LANGUAGES.ENGLISH_US',
    hourCycle: 'h12',
    onlyNumbersDateFormat: 'MDY',
    onlyNumbersDatePlaceholder: ['MM', 'DD', 'YYYY'],
    onlyNumbersDateCaption: ['Month', 'Day', 'Year']
  },
  {
    code: LanguageCodesEnum.PT_BR,
    defaultFor: 'pt',
    dateFns: ptBR,
    calendarDateFormat: 'dd/MM/yyyy',
    shortDateFormat: 'd-MMM',
    longDateFormat: 'dd-MMM-yyyy',
    timeFormat: 'HH:mm',
    datetimeFormat: 'dd-MMM-yyyy HH:mm',
    dayNamesForCalendar: ['D','S','T','Q','Q','S','S'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthShortNames: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    name: 'SETTINGS.LANGUAGES.PORTUGUESE_BR',
    hourCycle: 'h23',
    onlyNumbersDateFormat: 'DMY',
    onlyNumbersDatePlaceholder: ['DD', 'MM', 'AAAA'],
    onlyNumbersDateCaption: ['Dia', 'Mês', 'Ano']
  }
];
