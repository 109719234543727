export enum ResultsEnum {
  OK = 'ok',
  ERROR = 'error'
}

export enum DeletionReasonsEnum {
  USER_REQUEST = 'user-request',
  EMAIL_UNVERIFIED = 'email-unverified'
}

export enum EventLogProductsEnum {
  APP = 'app',
  DASHBOARD = 'dashboard',
  //CORP_DASHBOARD = 'corp-dashboard',
  PSYCHOEDUCATION = 'psychoeducation',
  MATCHMAKING = 'matchmaking',
  OPS = 'ops',
  WEBSITE = 'website'
}

export enum SidesEnum {
  CLIENT_UI = 'client-ui',
  CLIENT_UX = 'client-ux',
  SERVER = 'server'
}

export enum AgentsEnum {
  USER = 'user',
  SYSTEM = 'system',
  SUPPORT = 'support',
  SENDGRID = 'sendgrid',
  TWILIO = 'twilio',
  // no longer used - but still exist in event logs in the database
  //PAYPAL = 'paypal',
  STRIPE = 'stripe'
}

export enum EventsEnum {
  ACCOUNT_CREATED = 'account-created',
  ACCOUNT_DELETED = 'account-deleted',
  USER_CREATED = 'user-created',
  USER_UPDATED = 'user-updated',
  SIGNUP_ATTEMPTED = 'signup-attempted',
  SIGNIN_ATTEMPTED = 'signin-attempted',
  SIGNED_OUT = 'signed-out',
  AUTHENTICATED = 'authenticated',
  PASSWORD_RECOVERY_ATTEMPTED = 'password-recovery-attempted',
  PASSWORD_RESET = 'password-reset',
  MINICARD_CLICKED = 'minicard-clicked',
  MURAL_TILE_CLICKED = 'mural-tile-clicked',

  UNIQUE_DAILY_AUTHENTICATED_EVENT = 'unique-daily-authenticated-event',

  DTR_CREATED = 'dtr-created',
  DTR_UPDATED = 'dtr-updated',
  DTR_DELETED = 'dtr-deleted',
  THOUGHT_CREATED = 'thought-created',
  THOUGHT_UPDATED = 'thought-updated',
  THOUGHT_DELETED = 'thought-deleted',

  INVITATION_SENT = 'invitation-sent',
  INVITATION_ACCEPTED = 'invitation-accepted',
  INVITATION_REFUSED = 'invitation-refused',
  INVITATION_CANCELLED = 'invitation-cancelled',

  //ACCESS_REQUEST_SENT = 'access-request-sent',
  ACCESS_REQUEST_ACCEPTED = 'access-request-accepted',
  ACCESS_REQUEST_REFUSED = 'access-request-refused',
  ACCESS_REQUEST_CANCELLED = 'access-request-cancelled',

  INTERCONNECTION_CREATED = 'interconnection-created',
  INTERCONNECTION_TERMINATED = 'interconnection-terminated',
  RECORD_SHARING_STOPPED = 'record-sharing-stopped',
  PATIENT_ARCHIVED = 'patient-archived',

  //MODULE_COMPLETED = 'module-completed',
  MODULE_OPENED = 'module-opened',
  MODULE_ACCESS_ATTEMPTED = 'module-access-attempted',
  //SCALE_COMPLETED = 'scale-completed',
  SCALE_OPENED = 'scale-opened',
  TRIAL_GRANTED = 'trial-granted',

  PUSH_CONFIGURED = 'push-configured',
  PUSH_RECEIVED = 'push-received',
  PUSH_CLICKED = 'push-clicked',

  REMINDER_CONFIGURED = 'reminder-configured',
  REMINDER_RECEIVED = 'reminder-received',
  REMINDER_CLICKED = 'reminder-clicked',

  MENU_NOTIFICATION_ACTION_TAKEN = 'menu-notification-action-taken',
  MENU_NOTIFICATION_IGNORED = 'menu-notification-ignored',

  HELP_TIP_CLICKED = 'help-tip-clicked',
  FAQ_CLICKED = 'faq-clicked',
  WHATSAPP_CLICKED = 'whatsapp-clicked',
  WHATSAPP_OPENED = 'whatsapp-opened',
  SUPPORT_TICKET_SENT = 'support-ticket-sent',

  MENU_HELP_OTHERS_CLIKED = 'menu-help-others-clicked',
  MENU_SHARE_COGNI_CODE_CLIKED = 'menu-share-cogni-code-clicked',
  MENU_GO_TO_DASHBOARD_CLIKED = 'menu-go-to-dashboard-clicked',
  MENU_GO_TO_MATCHMAKING_CLIKED = 'menu-go-to-matchmaking-clicked',
  MENU_GO_TO_PAGE_CLIKED = 'menu-go-to-page-clicked',

  PAGE_ACCESSED_EVENT = 'page-accessed-event',
  CONTENT_ACCESSED_EVENT = 'content-accessed-event',

  // no longer used - but still exist in event logs in the database
  //RECONFIRMATION_ACCEPTED = 'reconfirmation-accepted',
  //RECONFIRMATION_REFUSED = 'reconfirmation-refused',
  //RECONFIRMATION_TIMEDOUT = 'reconfirmation-timed-out',

  SCHEDULE_INTERVIEW_INVITATION_SET = 'schedule-interview-invitation-set',
  SCHEDULE_INTERVIEW_INVITATION_SEEN = 'schedule-interview-invitation-seen',
  SCHEDULE_INTERVIEW_INVITATION_CLICKED = 'schedule-interview-invitation-clicked',
  SCHEDULE_INTERVIEW_INVITATION_REJECTED = 'schedule-interview-invitation-rejected',

  MATCHMAKING_FILTERS_QUIZ_SUBMITTED = 'matchmaking-filters-quiz-submitted',
  MATCHMAKING_SEARCH_REQUESTED = 'matchmaking-search-requested',
  MATCHMAKING_NEXT_PAGE_REQUESTED = 'matchmaking-next-page-requested',
  MATCHMAKING_PROFESSIONAL_ADDED = 'matchmaking-professional-added',
  MATCHMAKING_PROFESSIONAL_CHANGED = 'matchmaking-professional-changed',
  MATCHMAKING_PROFESSIONAL_REMOVED = 'matchmaking-professional-removed',
  MATCHMAKING_PROFESSIONAL_FILTERED = 'matchmaking-professional-filtered',
  MATCHMAKING_PROFESSIONAL_LISTED = 'matchmaking-professional-listed',
  MATCHMAKING_PROFESSIONAL_SEEN = 'matchmaking-professional-seen',
  MATCHMAKING_PROFESSIONAL_CLICKED = 'matchmaking-professional-clicked',
  MATCHMAKING_PROFESSIONAL_CONTACTED = 'matchmaking-professional-contacted',
  MATCHMAKING_VIDEO_PLAYED = 'matchmaking-video-played',
  MATCHMAKING_TEASER_SEEN = 'matchmaking-teaser-seen',
  MATCHMAKING_TEASER_CLICKED = 'matchmaking-teaser-clicked',
}

export enum EventPageIdsEnum {
  TERMS_OF_USE = 'terms-of-use',
  PRIVACY_POLICY = 'privacy-policy',
  RECORD_SHARING = 'record-sharing',
  //CORP_TRAINING = 'corp-training',
  REMINDERS = 'reminders',
  USAGE_TIPS = 'usage-tips',
  SUPPORT = 'support',
  PROFILE_UPDATE = 'profile-update',
  COGNI_WEBSITE = 'cogni-website',
  INSTAGRAM = 'instagram',
  MANIFESTO = 'manifesto',
  MATCHMAKING = 'matchmaking',
  MATCHMAKING_SALES = 'matchmaking-sales',
  MATCHMAKING_FILTERS = 'matchmaking-filters',
  MATCH_SALES = 'match-sales',
  SALES = 'sales',
}
