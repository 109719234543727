import {
  GetUserViewRequest,
} from './request-types'

/** @deprecated */
export enum AuthenticatedRequestTypesEnum {
  /** @deprecated */
  GET_USER = 'get-user', // TODO remove AFTER 3.1.2
}

/** @deprecated */
export type AuthenticatedCFRequest = {
  type: AuthenticatedRequestTypesEnum
  request?: GetUserViewRequest
}
