import { Injectable } from '@angular/core';
import {
  StatusEnum,
  ErrorsEnum,
  AuthClient,
  ProductsEnum,
  DashAuthenticatedRequestTypesEnum,
  CreateDashUserRequest,
  MobilePhoneNumber,
  CreateDashUserResponse,
  UpdateDashUserProfileRequest,
  UpdateDashUserProfileResponse,
  RtdbClient,
  SendEmailVerificationActionLinkRequest,
  LanguageCodesEnum,
  DashNoticesUponLaunchEnum,
  DashUserView,
  SuLoginRequest,
  SuLoginResponse,
  AuthenticationProviderEnum,
} from 'src/libs';
import { CloudFunctionsService, EventLogService } from 'src/app/core';
import { DashUserProfileModel, PersonalInformation, UserGuardsModel, UserIdentityModel, UserOptionsModel, UserProfessionalInformationModel } from '../types';
import { DashUserViewFactory } from '../factories';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  #userIdentity: UserIdentityModel;
  #userOptions: UserOptionsModel;
  #userGuards: UserGuardsModel;
  #userProfessionalInformation: UserProfessionalInformationModel;

  constructor(
    private authClient: AuthClient,
    private eventLogService: EventLogService,
    private functionsService: CloudFunctionsService,
    private defaultRtdbClient: RtdbClient,
  ) { }

  async logIn(email: string, password: string) {
    await this.authClient.signIn(email, password);
    await this.eventLogService.insertSignInAttemptEvent(email);
    await this.eventLogService.insertAuthenticatedEvent(AuthenticationProviderEnum.INTERNAL);
  }

  async logOut() {
    const uid = this.auth.uid;
    await this.authClient.signOut();
    await this.eventLogService.insertSignOutEvent(uid);
    this.clear();
  }

  async setInitialUserState() {
    this.#userIdentity = DashUserViewFactory.createUserIdentity({
      uid: this.uid,
      email: this.email,
      cognicode: this.auth.customClaims?.cognicode,
      displayName: this.auth.displayName
    });
  }

  async suLogin(event: KeyboardEvent) {
    if (event.ctrlKey && event.shiftKey && (event.key === 'L' || event.key === 'l')) {
      event.preventDefault();
      const email = prompt('E-mail')
      if (email) {
        const request: SuLoginRequest = {
          callerId: ProductsEnum.COGNI_DASHBOARD,
          email,
        };
        const response = (await this.functionsService.dashAuthenticatedCall({
          type: DashAuthenticatedRequestTypesEnum.SU_LOGIN,
          request
        })) as SuLoginResponse;
        if (!response.token) {
          alert('Computer says no!')
        } else {
          await this.logOut()
          location.href = `${location.origin}/action?mode=customSignIn&oobCode=${response.token}`
        }
      }
    }
  }

  setInitialUserInformation(view: DashUserView) {
    if (view) {
      this.#userIdentity = DashUserViewFactory.reconstituteUserIdentity(this.#userIdentity, {...view});
      this.#userProfessionalInformation = DashUserViewFactory.reconstituteProfessionalInformation(view);
      this.#userOptions = DashUserViewFactory.reconstituteUserOptions(view);
      this.#userGuards = DashUserViewFactory.reconstituteUserGuards(view);
      if (this.auth.customClaims?.cogniAdmin) {
        document.addEventListener('keydown', (event) => this.suLogin(event));
      }
    }
    return this.userProfile;
  }

  clear() {
    this.#userIdentity = undefined;
    this.#userProfessionalInformation = undefined;
    this.#userOptions = undefined;
    this.#userGuards = undefined;
  }

  isUserInformationReady() {
    return this.#userIdentity && this.#userGuards && this.#userOptions && this.userOptions;
  }

  isProfileComplete() {
    return this.#userGuards?.dashTermsVersionAccepted && this.#userIdentity?.cognicode;
  }

  get userProfile(): DashUserProfileModel {
    return {
      identity: this.#userIdentity,
      professionalInformation: this.#userProfessionalInformation,
      options: this.#userOptions,
      guards: this.#userGuards,
    };
  }

  get userIdentity() {
    return this.#userIdentity;
  }

  get userProfessionalInformation() {
    return this.#userProfessionalInformation;
  }

  get userOptions() {
    return this.#userOptions;
  }

  get userGuards() {
    return this.#userGuards;
  }

  async reloadCurrentUser() {
    return this.auth.reloadCurrentUser();
  }

  async reauthenticateUser(password: string) {
    return this.auth.reauthenticateUser(password);
  }

  async updateUserPassword(password: string) {
    return this.auth.updateUserPassword(password);
  }

  get auth() {
    return this.authClient;
  }

  get uid() {
    return this.authClient.uid;
  }

  get email() {
    return this.authClient.email;
  }

  get signedIn() {
    return this.authClient.signedIn;
  }

  get emailVerified() {
    return this.authClient.emailVerified;
  }

  verifyEmail(oobCode: string) {
    return this.authClient.applyActionCode(oobCode);
  }

  async updateEmail(newEmail: string) : Promise<string> {
    try {
      if (this.auth.email !== newEmail) {
        await this.auth.updateUserEmail(newEmail);
      }
      //await this.sendEmailVerification();
      return StatusEnum.OK;
    }
    catch (ex) {
      if (ex.code === ErrorsEnum.AUTH_EMAIL_ALREADY_EXISTS) {
        return ErrorsEnum.AUTH_EMAIL_ALREADY_EXISTS;
      }
      return StatusEnum.ERROR;
    }
  }

  signIn(email:string, emailLink: string) {
    if (this.authClient.isSignInWithEmailLink(emailLink)) {
      return this.authClient.signInWithEmailLink(email, emailLink);
    }
  }

  async customTokenSignIn(customToken: string) {
    await this.authClient.customTokenSignIn(customToken);
    // TODO: get the provider from the oauth flow somehow
    await this.eventLogService.insertAuthenticatedEvent(AuthenticationProviderEnum.GOOGLE);
  }

  async createUserRequest(personalInformation: PersonalInformation, telephone: MobilePhoneNumber) {
    const request: CreateDashUserRequest = {
      callerId: ProductsEnum.COGNI_DASHBOARD,
      firstName: personalInformation.firstName,
      lastName: personalInformation.lastName,
      gender: personalInformation.gender,
      professionalInformation: personalInformation.professionalInformation,
      mobilePhoneNumber: {
        internationalFormat: telephone.internationalFormat,
        verified: telephone.verified,
        countryCode: telephone.countryCode
      }
    };
    const response: CreateDashUserResponse = (await this.functionsService.dashAuthenticatedCall({
      type: DashAuthenticatedRequestTypesEnum.CREATE_USER,
      request
    }) as CreateDashUserResponse);
    if (response.status === StatusEnum.OK){
      if (response.user) {
        this.#userIdentity = DashUserViewFactory.reconstituteUserIdentity(this.#userIdentity, response.user);
        this.#userOptions = DashUserViewFactory.reconstituteUserOptions(response.user);
        this.#userProfessionalInformation = DashUserViewFactory.reconstituteProfessionalInformation(response.user);
        this.#userGuards = DashUserViewFactory.reconstituteUserGuards(response.user);
      }
    }
    return response;
  }

  async updateProfileInfo(personalInformation: PersonalInformation, telephone: MobilePhoneNumber) {
    const request: UpdateDashUserProfileRequest = {
      callerId: ProductsEnum.COGNI_DASHBOARD,
      firstName: personalInformation.firstName,
      lastName: personalInformation.lastName,
      gender: personalInformation.gender,
      professionalInformation: personalInformation.professionalInformation,
      dashTermsVersionAccepted: '2021-10-15T00:00:00.000Z', // TODO make non-static
      privacyPolicyVersionAccepted: '2021-10-15T00:00:00.000Z', // TODO make non-static
      mobilePhoneNumber: {
        internationalFormat: telephone.internationalFormat,
        verified: telephone.verified,
        countryCode: telephone.countryCode
      }
    };
    const response: UpdateDashUserProfileResponse = (await this.functionsService.dashAuthenticatedCall({
      type: DashAuthenticatedRequestTypesEnum.UPDATE_USER_PROFILE,
      request: request
    }) as UpdateDashUserProfileResponse);
    if (response.status === StatusEnum.OK){
      if (response.user) {
        this.#userIdentity = DashUserViewFactory.reconstituteUserIdentity(this.#userIdentity, response.user);
        this.#userOptions = DashUserViewFactory.reconstituteUserOptions(response.user);
        this.#userProfessionalInformation = DashUserViewFactory.reconstituteProfessionalInformation(response.user);
        this.#userGuards = DashUserViewFactory.reconstituteUserGuards(response.user);
      }
    }
    return response;
  }

  async sendAccountVerificationEmail(languageCode: LanguageCodesEnum) {
    const request: SendEmailVerificationActionLinkRequest = {
      callerId: ProductsEnum.COGNI_DASHBOARD,
      languageCode,
      url: 'https://localhost:8100' // TODO
    }
    const result = await this.functionsService.dashAuthenticatedCall({
      type: DashAuthenticatedRequestTypesEnum.SEND_EMAIL_VERIFICATION_ACTION_LINK,
      request: request
    });
    if (result.status !== StatusEnum.OK) {
      console.error(result);
    }
    return result;
  }

  async updateInterviewOption(option: number) {
    return this.defaultRtdbClient.updateChild(`/${this.privateDashSettingsDBPath}/${this.uid}`, {
      interviewOption: option
    });
  }

  async clearInterviewOption() {
    return this.defaultRtdbClient.updateChild(`/${this.privateDashSettingsDBPath}/${this.uid}`, {
      interviewOption: {},
      noticeUponLaunch: DashNoticesUponLaunchEnum.NONE
    });
  }

  async clearNoticeUponLaunch() {
    return this.defaultRtdbClient.updateChild(`/${this.privateDashSettingsDBPath}/${this.uid}`, {
      noticeUponLaunch: DashNoticesUponLaunchEnum.NONE
    });
  }

  private privateDashSettingsDBPath = '/privateDashSettings'
}
