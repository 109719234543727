import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { DeviceInfo, DeviceTypesEnum, OperatingSystemsEnum, PlatformsEnum } from 'src/libs';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  #device: DeviceInfo;

  constructor(private ionicPlatform: Platform) {}

  async initializeDeviceInfo(isProduction: boolean) {
    await this.ionicPlatform.ready();
    const uuid = await Device.getId();
    const info = await Device.getInfo();
    const languageTag = await Device.getLanguageTag();
    this.#device = {
      deviceUuid: uuid.identifier,
      languageCode: languageTag.value,
      model: info.model,
      type: this.deviceType,
      os: (info.operatingSystem as string) as OperatingSystemsEnum,
      osVersion: info.osVersion,
      platform: this.platform
    };
    try {
      const app = await App.getInfo();
      this.#device.appVersion = app.version;
      this.#device.appBuild = app.build;
      if (!isProduction) {
        this.#device.appVersion += '-dev';
      }
    }
    catch (error) {
      this.#device.appVersion = 'webversion';
      this.#device.appBuild = '0';
      if (!isProduction) {
        this.#device.appVersion += '-dev';
      }
    }
  }

  get device() {
    return this.#device;
  }

  get deviceUuid() {
    return this.#device.deviceUuid;
  }

  get deviceLanguage() {
    return this.#device.languageCode;
  }

  get deviceOperatingSystem() {
    return this.#device.os;
  }

  get deviceOperatingSystemVersion() {
    return this.#device.osVersion;
  }

  get devicePlatform() {
    return this.#device.platform;
  }

  get appVersion() {
    return this.#device.appVersion;
  }

  get isCapacitor(): boolean {
    return this.ionicPlatform.is('capacitor');
  }

  get isAndroid(): boolean {
    return this.ionicPlatform.is('android');
  }

  get isIOs(): boolean {
    return this.ionicPlatform.is('ios');
  }

  get isAndroid13(): boolean {
    return this.ionicPlatform.is('android') && this.#device.osVersion.startsWith('13');
  }

  get isCapacitorAndroid(): boolean {
    return this.ionicPlatform.is('capacitor') && this.ionicPlatform.is('android');
  }

  get isCapacitorIOs(): boolean {
    return this.ionicPlatform.is('capacitor') && this.ionicPlatform.is('ios');
  }

  get platform(): PlatformsEnum {
    if(this.ionicPlatform.is('android')) {
      return PlatformsEnum.ANDROID;
    }
    if(this.ionicPlatform.is('ios')){
      return PlatformsEnum.IOS;
    }
    return PlatformsEnum.WEB;
  }

  private get deviceType(): DeviceTypesEnum {
    if(this.ionicPlatform.is('tablet') || this.ionicPlatform.is('phablet')) {
      return DeviceTypesEnum.TABLET;
    }
    if(this.ionicPlatform.is('mobile') || this.ionicPlatform.is('mobileweb')){
      return DeviceTypesEnum.PHONE;
    }
    if(this.ionicPlatform.is('desktop')){
      return DeviceTypesEnum.DESKTOP;
    }
    return DeviceTypesEnum.UNKNOWN;
  }
}
