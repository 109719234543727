export enum TaxPayerIdsEnum {
  CNPJ = 'CNPJ',
  CPF = 'CPF',
  TIN = 'TIN' // tax payer identification number for foreign subscribers
}

export enum ProductSubscriptionCodesEnum {
  COGNI_DASHBOARD = 'cogni-dashboard',
  //COGNI_CORP_PSYEDU = 'cogni-corp-psyedu',
  COGNI_PSYEDU = 'cogni-psyedu',
  COGNI_MATCHMAKING = 'cogni-matchmaking'
}

export enum PsyeduSubscriptionTypesEnum {
  NONE = 'NONE',
  TRIAL = 'TRIAL',
  //PILOT = 'PILOT',
  BASIC = 'BASIC'
}

export enum DashboardSubscriptionTypesEnum {
  FREEMIUM = 'FREEMIUM',
  BASIC = 'BASIC',
  STUDENT = 'STUDENT',
}

export enum MatchmakingSubscriptionTypesEnum {
  BETA = 'BETA',
}

export type SubscriptionTypes = PsyeduSubscriptionTypesEnum | DashboardSubscriptionTypesEnum | MatchmakingSubscriptionTypesEnum

export enum SubscriberTypesEnum {
  //CORPORATE = 'CORPORATE',
  PERSONAL = 'PERSONAL',
}

export enum SubscriptionStatusesEnum {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED', // TODO used for psyedu... we may not need it
  CANCELLED = 'CANCELLED', // cancelled, waiting for last cycle completion
  TERMINATED = 'TERMINATED',
  INCOMPLETE = 'INCOMPLETE', // when payment fails at signup
  IGNORED = 'IGNORED' // incomplete subscriptions that have passed through audit
}

export enum PaymentStatusesEnum {
  NONE = 'NONE',
  INCOMPLETE = 'INCOMPLETE',
  UP_TO_DATE = 'UP_TO_DATE',
  OVERDUE = 'OVERDUE', // payment overdue
  //NEAR_SUSPENSION = 'NEAR_SUSPENSION', // TODO do we still need this?
  SUSPENDED = 'SUSPENDED', // account suspended after one-week payment overdue
  BLOCKED = 'BLOCKED', // account blocked after two-week payment overdue
}

export enum SubscriptionFailuresEnum {
  NO_FUNDS_AVAILABLE = 'NO_FUNDS_AVAILABLE',
  CARD_EXPIRED = 'CARD_EXPIRED',
  CARD_DECLINED = 'CARD_DECLINED',
  CARD_BLOCKED = 'CARD_BLOCKED',
  CARD_TEMP_BLOCKED = 'CARD_TEMP_BLOCKED',
  CARD_NOT_SUPPORTED = 'CARD_NOT_SUPPORTED',
  CARD_INCORRECT_NUMBER = 'CARD_INCORRECT_NUMBER',
  CARD_INCORRECT_CVC = 'CARD_INCORRECT_CVC',
  CARD_DO_NOT_RETRY = 'CARD_DO_NOT_RETRY',
  INVALID_ACCOUNT = 'INVALID_ACCOUNT',
  PIX_OVERDUE = 'PIX_OVERDUE',
  GENERIC_DECLINE = 'GENERIC_DECLINE',
  NOT_ALLOWED = 'NOT_ALLOWED',
}

export enum SubscriptionRecurrenciesEnum {
  MONTHLY = '1mo',
  QUATERLY = '3mo',
  SEMIANNUAL = '6mo',
  ANNUAL = '1yr'
}

export enum SubscriptionTransactionsEnum {
  SIGNUP = 'signup',
  CANCELLATION = 'cancellation',
  PAYMENT = 'payment',
  ADJUSTMENT = 'adjustment',
  REFUND = 'refund',
  EXPIRATION = 'expiration',
  TERMINATION = 'termination',
  SUSPENSION = 'suspension',
  BLOCKAGE = 'blockage',
  UNLOCK = 'unlock',
}

export enum CancellationReasonsEnum {
  CANCELLATION_REQUESTED = 'cancellation_requested',
  PAYMENT_FAILED = 'payment_failed',
  PAYMENT_DISPUTED = 'payment_disputed',
}

export enum AccountsEnum {
  // 'Parallel' is no longer used but still exist in transactions and subscriptions in database
  COGNI = 'Cogni'
}

export enum GatewaysEnum {
  // 'paypal-buttons' and 'banco-do-brasil' are no longer used but still exist in subscriptions in database
  INTER = 'banco-inter',
  NUBANK = 'banco-nubank',
  STRIPE = 'stripe',
  STUDENT = 'student',
}

export enum CollectionMethodsEnum {
  // matches Stipe collection_method
  SEND_INVOICE = 'send_invoice',
  CHARGE_AUTOMATICALLY = 'charge_automatically'
}

export enum StripeProducts {
  DASH_MONTHLY = 'prod_MbethC51D24k1T',
  DASH_ANNUAL = 'prod_Mbf96DFEPXXQSS'
}

export enum PaymentMethodsEnum {
  DOC = 'doc',
  TED = 'ted',
  TEF = 'tef',
  PIX = 'pix',
  DEPOSIT = 'deposit',
  CARD = 'card'
}

export enum OriginsEnum {
  // 'paypal-ipn' is no longer used but still exist in transactions in database
  MANUAL = 'manual', // manual insertion
  STRIPE = 'stripe-event'
}

export enum CurrenciesEnum {
  BRL = 'brl'
}

export enum PlanCodesEnum {
  PLAN_MONTHLY = '-mo',
  PLAN_QUATERLY = '-tri',
  PLAN_SEMIANNUAL = '-sem',
  PLAN_ANNUAL = '-an'
}
