import { DocumentData } from 'firebase/firestore'
import { Functions, getFunctions, httpsCallable } from 'firebase/functions'
import { getApp } from 'firebase/app'
import {
  FirebaseInitializer,
  FirebaseInitializerStatusesEnum,
} from './firebase-initializer'


export class CloudFunctionsClient {

  #functions: Functions

  constructor(firebaseInitializer: FirebaseInitializer) {
    firebaseInitializer.initializationStatus$.subscribe(state => {
      if (state === FirebaseInitializerStatusesEnum.INITIALIZED) {
        this.#functions = getFunctions(getApp())
      }
    })
  }

  async call(
    functionName: string,
    request: DocumentData
  ): Promise<any> {
    try {
      const callableFunction = httpsCallable(this.#functions, functionName)
      const result = await callableFunction(request)
      if (result && result.data) {
        return result.data as DocumentData
      } else {
        return {
          status: 'ERROR',
          error: 'missing-data-property'
        }
      }
    } catch (error) {
      return {
        status: 'ERROR',
        error: 'unknown',
        message: error.message
      }
    }
  }
}
