import { PatientModel } from '..';

const displayName = (patient: PatientModel): string => {
  return [patient.firstName, patient.lastName[0]].join(' ');
}

const fullDisplayName = (patient: PatientModel): string => {
  return [patient.firstName, patient.lastName].join(' ');
}

export {
  displayName,
  fullDisplayName
}