import { InvitationDBDocument, PatientDBDocument, PatientNotificationTypesEnum } from '../../../../libs';
import { PatientTypesEnum, PatientModel, PatientModelStatusesEnum, ViewOrderEnum } from '..';
import { convertPatientStatus, convertPatientStatusToViewOrder } from '../helpers/convert-patient-status';

export const PatientFactory = {

  createPatient: (
    document: PatientDBDocument
  ): PatientModel | undefined => {
    if (document) {
      let patient = {
        type: PatientTypesEnum.PATIENT,
        patientId: document.patientId,
        therapistId: document.therapistId,
        interconnectionId: document.interconnectionId,
        status: convertPatientStatus(document.status),
        gender: document.gender,
        firstName: document.firstName,
        lastName: document.lastName,
        invitationId: null,
        selected: false,
        notifications: document.notifications,
        archivedAtDate: document.updatedAtDate || null,
        archiveReason: document.archiveReason || null,
        lastDTREditionAtDate: document.lastDTREditionAtDate || null,
        viewOrder: null,
      };
      patient.viewOrder = convertPatientStatusToViewOrder(document.status);
      return patient;
    }
    return undefined;
  },

  updatePatient: (
    patient: PatientModel,
    document: PatientDBDocument
  ): PatientModel | undefined => {
    if (document) {
      let updatePatient =  {
        ...patient,
        type: PatientTypesEnum.PATIENT,
        interconnectionId: document.interconnectionId,
        status: convertPatientStatus(document.status),
        notifications: []
      };
      updatePatient.viewOrder = convertPatientStatusToViewOrder(document.status);
      return updatePatient;
    }
    return undefined;
  },

  createInvitation: (
    document: InvitationDBDocument
  ): PatientModel | undefined => {
    if (document) {
      return {
        type: PatientTypesEnum.INVITATION,
        invitationId: document.invitationId,
        status: PatientModelStatusesEnum.INVITATION_PENDING,
        gender: document.patientGender,
        firstName: document.patientFirstName,
        lastName: document.patientLastName,
        patientId: document.patientId,
        therapistId: document.therapistId,
        interconnectionId: null,
        selected: false,
        lastDTREditionAtDate: null,
        notifications: [PatientNotificationTypesEnum.INVITATION_RECEIVED],
        viewOrder: ViewOrderEnum.INVITATION_PENDING,
        archivedAtDate: null,
        archiveReason: null
      };
    }
    return undefined;
  },

  updateInvitation: (
    patient: PatientModel,
    document: InvitationDBDocument
  ): PatientModel | undefined => {
    if (document) {
      return {
        ...patient,
        type: PatientTypesEnum.INVITATION,
        invitationId: document.invitationId,
        status: PatientModelStatusesEnum.INVITATION_PENDING,
        gender: document.patientGender,
        firstName: document.patientFirstName,
        lastName: document.patientLastName,
        notifications: [PatientNotificationTypesEnum.INVITATION_RECEIVED],
        viewOrder: ViewOrderEnum.INVITATION_PENDING
      };
    }
    return undefined;
  }
}