export const numericRegex = /^([0-9]+)$/
export const alphabeticRegex = /^([a-zA-Z]+)$/
export const alphanumericRegex = /^([a-zA-Z0-9]+)$/
export const extendedAlphanumericRegex = /^([a-zA-Z0-9-_]+)$/

// addapted from https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
export const emailRegex =
  /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]*[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-])?@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

export const dayRegex = /^(0[1-9]|[12]\d|3[01])$/
export const monthRegex = /^(0[1-9]|1[012])$/
export const yearRegex = /^((19|20)\d\d)$/
