import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { LanguageService } from 'src/app/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorAlertService {

  constructor(
    private alertController: AlertController,
    private languageService: LanguageService) { }

  async presentSimpleAlert(messages: string[], header?: string) {
    let translatedMessage = '';
    for (const message of messages) {
      translatedMessage = [translatedMessage, this.languageService.translate(message)].join(' ');
    }
    const alert = await this.alertController.create({
      header: header ? this.languageService.translate(header) : undefined,
      message: translatedMessage,
      buttons: [this.languageService.translate('COMMON.OK')]
    });
    await alert.present();
  }

  async presentAlertAndCallback(messages: string[], header: string, callback: () => void) {
    let translatedMessage = '';
    for (const message of messages) {
      translatedMessage = [translatedMessage, this.languageService.translate(message)].join(' ');
    }
    const alert = await this.alertController.create({
      header: header ? this.languageService.translate(header) : undefined,
      message: translatedMessage,
      buttons: [{
        text: this.languageService.translate('COMMON.OK'),
        handler: () => {
          callback();
        }
      }]
    });
    await alert.present();
  }

  async presentAlertAndCallbackOnOk(messages: string[], header: string, callback: () => void) {
    let translatedMessage = '';
    for (const message of messages) {
      translatedMessage = [translatedMessage, this.languageService.translate(message)].join(' ');
    }
    const alert = await this.alertController.create({
      header: header ? this.languageService.translate(header) : undefined,
      message: translatedMessage,
      buttons: [
        {
          text: this.languageService.translate('COMMON.CANCEL'),
        },
        {
          text: this.languageService.translate('COMMON.OK'),
          handler: () => {
            callback();
          }
        }
      ]
    });
    await alert.present();
  }
}
