export const delay = (milliseconds: number) =>
  new Promise(resolve => setTimeout(resolve, milliseconds))

export const getDaysInFuture = (daysInFuture: number) => {
  const aDayInTheFuture = new Date()
  aDayInTheFuture.setDate(aDayInTheFuture.getDate() + daysInFuture)
  return aDayInTheFuture
}

export const getDaysInFutureStartingAt = (daysInFuture: number, date: Date) => {
  const aDayInTheFuture = new Date(date)
  aDayInTheFuture.setDate(aDayInTheFuture.getDate() + daysInFuture)
  return aDayInTheFuture
}

export const getSecondsInFutureStartingAt = (secondsInFuture: number, date: Date) => {
  const secondsInTheFuture = new Date(date)
  secondsInTheFuture.setSeconds(secondsInTheFuture.getSeconds() + secondsInFuture)
  return secondsInTheFuture
}

export const getMinutesInFutureStartingAt = (minutesInFuture: number, date: Date) => {
  const minutesInTheFuture = new Date(date)
  minutesInTheFuture.setMinutes(minutesInTheFuture.getMinutes() + minutesInFuture)
  return minutesInTheFuture
}

export const getMillisecondsInFutureStartingAt = (millisecondsInFuture: number, date: Date) => {
  const millisecondsInTheFuture = new Date(date)
  millisecondsInTheFuture.setMilliseconds(millisecondsInTheFuture.getMilliseconds() + millisecondsInFuture)
  return millisecondsInTheFuture
}

export const getMonthsInTheFutureStartingAt = (monthsInFuture: number, date: Date) => {
  const monthsInTheFuture = new Date(date)
  monthsInTheFuture.setMonth(monthsInTheFuture.getMonth() + monthsInFuture)
  return monthsInTheFuture
}

export const getSemestersInTheFutureStartingAt = (semestersInFuture: number, date: Date) => {
  const monthsInTheFuture = new Date(date)
  monthsInTheFuture.setMonth(monthsInTheFuture.getMonth() + (semestersInFuture * 6))
  return monthsInTheFuture
}

export const getYearsInTheFutureStartingAt = (yearsInFuture: number, date: Date) => {
  const yearsInTheFuture = new Date(date)
  yearsInTheFuture.setFullYear(yearsInTheFuture.getFullYear() + yearsInFuture)
  return yearsInTheFuture
}

export const getHoursInFuture = (hoursInFuture: number) => {
  const aTimeInTheFuture = new Date()
  aTimeInTheFuture.setHours(aTimeInTheFuture.getHours() + hoursInFuture)
  return aTimeInTheFuture
}

export const getMinutesInFuture = (minutesInFuture: number) => {
  const aTimeInTheFuture = new Date()
  aTimeInTheFuture.setMinutes(aTimeInTheFuture.getMinutes() + minutesInFuture)
  return aTimeInTheFuture
}

export const getSecondsInFuture = (secondsInFuture: number) => {
  const aTimeInTheFuture = new Date()
  aTimeInTheFuture.setSeconds(aTimeInTheFuture.getSeconds() + secondsInFuture)
  return aTimeInTheFuture
}

export const getDaysInPast = (date: Date, daysInPast: number) => {
  const aTimeInThePast = new Date(date)
  aTimeInThePast.setDate(aTimeInThePast.getDate() - daysInPast)
  return aTimeInThePast
}

export const getMinutesInPast = (date: Date, minutesInPast: number) => {
  const aTimeInThePast = new Date(date)
  aTimeInThePast.setMinutes(aTimeInThePast.getMinutes() - minutesInPast)
  return aTimeInThePast
}

export const getHoursInPast = (date: Date, hoursInPast: number) => {
  const aTimeInThePast = new Date(date)
  aTimeInThePast.setHours(aTimeInThePast.getHours() - hoursInPast)
  return aTimeInThePast
}

export const getSecondsInPast = (date: Date, secondsInPast: number) => {
  const aTimeInThePast = new Date(date)
  aTimeInThePast.setSeconds(aTimeInThePast.getSeconds() - secondsInPast)
  return aTimeInThePast
}

export const getMillisecondsInPast = (date: Date, millisecondsInPast: number) => {
  const aTimeInThePast = new Date(date)
  aTimeInThePast.setMilliseconds(aTimeInThePast.getMilliseconds() - millisecondsInPast)
  return aTimeInThePast
}

export const isDateInPast = (date: Date) => {
  const currentDate = new Date()
  if (date.toISOString() < currentDate.toISOString()) {
    return true
  }
  return false
}

export const elapsedHours = (date: Date) => {
  const today = new Date()
  const diff = Math.abs(today.getTime() - date.getTime())
  return Math.ceil(diff / (1000 * 3600))
}

export const elapsedDays = (date: Date) => {
  const today = new Date()
  const diff = Math.abs(today.getTime() - date.getTime())
  return Math.ceil(diff / (1000 * 3600 * 24))
}

export const yearsFromBirthdate = (birthdate: string) => {
  const today = new Date()
  const date = new Date(birthdate)
  let ageAux = today.getFullYear() - date.getFullYear()
  const month = today.getMonth() - date.getMonth()
  if (month < 0 || (month === 0 && today.getDate() < date.getDate())) {
    ageAux--
  }
  return ageAux
}

export const getDateFromEpochTimestampInSeconds = (
  epochTimestampInSeconds: number
) => {
  return new Date(epochTimestampInSeconds * 1000)
}

export const getDateFromEpochTimestampInMilliseconds = (
  epochTimestampInMilliseconds: number
) => {
  return new Date(epochTimestampInMilliseconds)
}

export const getRemainingTimeUntil = (futureDate: Date) => {
  const now = new Date().getTime()
  const distance = futureDate.getTime() - now
  const days = Math.floor(distance / (1000 * 60 * 60 * 24))
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((distance % (1000 * 60)) / 1000)
  return {
    days,
    hours,
    minutes,
    seconds,
    totalInSecods: distance
  }
}
