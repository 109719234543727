import { Inject, Injectable } from '@angular/core';    
import { DOCUMENT } from '@angular/common';  
import { TranslateService } from '@ngx-translate/core';
import { GenderCodesEnum, LanguageCodesEnum } from 'src/libs';
import { DeviceService, LocalStorageService, SupportedLocale } from '../..';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private static _currentLanguage: SupportedLocale;

  private static supportedLocales: SupportedLocale[];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService,
    private deviceService: DeviceService,
    private localStorageService: LocalStorageService
  ) { }

  /**
   * Returns current language which was not necessarily chosen by the user.
   */
  get currentLanguage(): LanguageCodesEnum {
    if (LanguageService._currentLanguage) {
      return LanguageService._currentLanguage.code;
    }
    return this.translateService.currentLang as LanguageCodesEnum;
  }

  /**
   * Returns the language chosen by the user.
   */
  get languageChosenByUser(): LanguageCodesEnum | undefined {
    if (LanguageService._currentLanguage) {
      return LanguageService._currentLanguage.code;
    }
    return undefined;
  }

  get locale(): string {
    return LanguageService._currentLanguage.code;
  }

  get calendarDateFormat(): string {
    return LanguageService._currentLanguage.calendarDateFormat;
  }

  get shortDateFormat(): string {
    return LanguageService._currentLanguage.shortDateFormat;
  }

  get longDateFormat(): string {
    return LanguageService._currentLanguage.longDateFormat;
  }

  get timeFormat(): string {
    return LanguageService._currentLanguage.timeFormat;
  }

  get datetimeFormat(): string {
    return LanguageService._currentLanguage.datetimeFormat;
  }

  get dateFns() {
    return LanguageService._currentLanguage.dateFns;
  }

  get monthNames() {
    return LanguageService._currentLanguage.monthNames;
  }

  get monthShortNames() {
    return LanguageService._currentLanguage.monthShortNames;
  }

  get dayNamesForCalendar() {
    return LanguageService._currentLanguage.dayNamesForCalendar;
  }

  get hourCycle(): string {
    return LanguageService._currentLanguage.hourCycle;
  }

  get onlyNumbersDateFormat() {
    return LanguageService._currentLanguage.onlyNumbersDateFormat;
  }

  get onlyNumbersDateCaption() {
    return LanguageService._currentLanguage.onlyNumbersDateCaption;
  }

  get onlyNumbersDatePlaceholder() {
    return LanguageService._currentLanguage.onlyNumbersDatePlaceholder;
  }

  translate(key: string) {
    return this.translateService.instant(key);
  }

  translateByGroup(group: string, key: string) {
    return this.translateService.instant([group, key].join('.'));
  }

  translateByGender(key: string, gender: GenderCodesEnum) {
    if (gender !== GenderCodesEnum.UNKNOWN) {
      return this.translateService.instant([key, gender].join('_'));
    }
    return this.translateService.instant([key, 'MALE'].join('_'));
  }

  translateByGenderAndReplacePlaceholder(key: string, gender: GenderCodesEnum, pattern: string, value: string) {
    const message = this.translateByGender(key, gender);
    return message.replace(pattern, value);
  }

  translateAndReplacePlaceholder(key: string, pattern: string, value: string) {
    const message = this.translate(key);
    return message.replace(pattern, value);
  }

  async setLanguage(languageCode: string) {
    const supportedLanguage = this.getSupportedLanguage(languageCode);
    if (supportedLanguage) {
      LanguageService._currentLanguage = supportedLanguage;
      await this.localStorageService.saveLanguageValue(LanguageService._currentLanguage.code);
      this.translateService.setDefaultLang(LanguageService._currentLanguage.code);
      this.translateService.use(LanguageService._currentLanguage.code);
      this.document.documentElement.lang = LanguageService._currentLanguage.code.split('-')[0]; 
      return;
    }
    console.error('Language Service - unsupported languageCode in setLanguage', languageCode);
  }

  async setInitialLanguage(supportedLocales: SupportedLocale[]) {
    LanguageService.supportedLocales = supportedLocales;
    const langs = [];
    supportedLocales.forEach(language => {
      langs.push(language.code);
    });
    this.translateService.addLangs(langs);
    const languageFromStorage = await this.localStorageService.readLanguageValue();
    let supportedLanguage = this.getSupportedLanguage(languageFromStorage);
    let languageCode: LanguageCodesEnum;
    if (supportedLanguage) {
      languageCode = supportedLanguage.code;
      LanguageService._currentLanguage = supportedLanguage;
    } else {
      const languageFromDevice = this.deviceService.deviceLanguage;
      supportedLanguage = this.getSupportedLanguage(languageFromDevice);

      if(!supportedLanguage) {
        supportedLanguage = this.getSupportedLanguage(this.translateService.getBrowserCultureLang());
      }

      if (supportedLanguage) {
        languageCode = supportedLanguage.code;
       } else {
        languageCode = 'en-US' as LanguageCodesEnum;
      }
    }

    this.translateService.setDefaultLang(languageCode);
    this.translateService.use(languageCode);
    this.document.documentElement.lang = languageCode.split('-')[0]; 
  }

  private getSupportedLanguage(code: string): any {
    const result = LanguageService.supportedLocales.find(language => language.code === code);
    if (result) {
      return result;
    }
    if (code && code.length >= 2) {
      const shorterCode = code.slice(0, 2);
      return LanguageService.supportedLocales.find(language => language.defaultFor === shorterCode);
    }
    return undefined;
  }
}
