import {
  ScreenWidthsEnum
} from '../..'

export const getScreenWidth = (
  width: number
): ScreenWidthsEnum => {
  if (width < 576) {
    return ScreenWidthsEnum.XS;
  }
  if (width < 768) {
    return ScreenWidthsEnum.SM;
  }
  if (width < 992) {
    return ScreenWidthsEnum.MD;
  }
  if (width < 1200) {
    return ScreenWidthsEnum.LG;
  }
  return ScreenWidthsEnum.XL
}
