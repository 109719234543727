import { DashNoticesUponLaunchEnum, PrivateDashSettingsDBDocument, ProtectedDashSettingsDBDocument } from '../../../../libs';
import { SettingsModel } from '../types'

export const DashSettingsFactory = {
  reconstituteSettings: (
    privateSettings: PrivateDashSettingsDBDocument,
    protectedSettings: ProtectedDashSettingsDBDocument
  ): SettingsModel | undefined => {
    return {
      bannerOption: protectedSettings?.bannerOption || null,
      showMhsNpsSurvey: protectedSettings?.showMhsNpsSurvey || false,
      interviewOption: privateSettings?.interviewOption || null,
      noticeUponLaunch: privateSettings?.noticeUponLaunch || DashNoticesUponLaunchEnum.NONE,
      mhsNpsSurveyLastTakenAtDate: privateSettings?.mhsNpsSurveyLastTakenAtDate ? new Date(privateSettings?.mhsNpsSurveyLastTakenAtDate) : null,
      skipManifesto: privateSettings?.skipManifesto || false,
      skipMatchmakingTeaser: privateSettings?.skipMatchmakingTeaser || false,
      skipMatchmakingMock: privateSettings?.skipMatchmakingMock || false,
    }
  },

  updatePrivateSettings: (
    currentSettings: SettingsModel,
    privateSettings: PrivateDashSettingsDBDocument,
  ): SettingsModel | undefined => {
    return {
      ...currentSettings,
      interviewOption: privateSettings?.interviewOption || null,
      noticeUponLaunch: privateSettings?.noticeUponLaunch || DashNoticesUponLaunchEnum.NONE,
      mhsNpsSurveyLastTakenAtDate: privateSettings?.mhsNpsSurveyLastTakenAtDate ? new Date(privateSettings?.mhsNpsSurveyLastTakenAtDate) : null,
      skipManifesto: privateSettings?.skipManifesto || false,
      skipMatchmakingTeaser: privateSettings?.skipMatchmakingTeaser || false,
      skipMatchmakingMock: privateSettings?.skipMatchmakingMock || false,
    }
  },

  updateMhsNpsSurveyLastTakenAtDate: (
    currentSettings: SettingsModel
  ): SettingsModel | undefined => {
    return {
      ...currentSettings,
      mhsNpsSurveyLastTakenAtDate: new Date()
    }
  },

  updateSkipManifesto: (
    currentSettings: SettingsModel
  ): SettingsModel | undefined => {
    return {
      ...currentSettings,
      skipManifesto: true
    }
  },

  updateSkipMatchmakingMock: (
    currentSettings: SettingsModel
  ): SettingsModel | undefined => {
    return {
      ...currentSettings,
      skipMatchmakingMock: true
    }
  },
}